.containerbuttonYesOrNo {
  display: flex;
  gap: 10px;
}

.contentYesOrNo {
  width: 10px;
}

.textYesOrNo {
  color: #FFF;
  font-family: "Fira Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  font-variant: all-small-caps;
  letter-spacing: 1.1px;
}

.buttonYesOrNo {
  width: 100%;
  height: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center
}

.yesPress {
  background: #57CE71;
  border-radius: 25px;
}

.yesPress * {
  color: #FFF;
  fill: #FFF;
}

.noPress {
  background: #FF6161;
  border-radius: 25px;
}

.noPress * {
  color: #FFF;
  fill: #FFF;
}

.notPress {
  border: 2px solid #999;
  background: #FFF;
  border-radius: 25px;
}

.notPress * {
  color: #999;
  fill: #999;
}

.unselected {
  background: #4D4D4D;
  border-radius: 25px;
}