@media print {
  .print {
    display: block !important;
    width: 750px;
    height: 1050px;
  }

  .no-print {
    display: none !important;
  }
}

.templateData {
  background: #fff;
  border-radius: 20px;
  width: auto;
  height: calc(100% - 60px);
  margin: 30px;
  padding: 50px;
  display: flex;
  flex-direction: column;
}

.templateData h2 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  color: #4d4d4d;
}

.container__category {
  display: flex;
  justify-content: space-between;
}

.add__button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: #62b8ae;
  border: 2px solid #62b8ae;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.add__button>svg {
  width: 24px;
  height: 24px;
}

.add__button>svg>path {
  fill: #ffffff;
}

.back__button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: #ffffff;
  border: 2px solid #f1f1f1;
  border-radius: 32px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.back__button>svg {
  width: 21px;
  height: 21px;
}

.delimiter {
  width: 100%;
  height: 1px;
  display: block;
  background: #f1f1f1;
  border-radius: 1px;
  /* margin: 30px 0; */
  margin-bottom: 0;
}


.body__editOnboarding {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: 10px;
}