:root {
  /*primary*/
  --green-100: #22c05e;
  --green-90: #67c700;
  --green-80: #7cdd15;
  --green-70: #caff24;
  --green-60: #cafe24;

  --green-0100: #62b8ae;
  /*Light*/
  --white-10: #ffffff;
  /*Dark*/
  --dark-100: #000000;
  --dark-90: #121212;
  --dark-80: #282828;
  --dark-70: #434343;
  /*gray*/
  --gray-110: #4d4d4d;
  --gray-100: #6a6a6a;
  --gray-90: #707070;
  --gray-85: #999999;
  --gray-80: #b9b9b9;
  --gray-70: #dbdbdb;
  --gray-60: #e8e8e8;
  --gray-50: #eaeaea;
  --gray-40: #e5e5e5;
  /*blue*/
  --blue-10: #99c1bf;

  --zoom-pixel: 2000px;
}

@font-face {
  font-family: "ampleLight";
  src: local("ampleLight"), url("../assets/fonts/AmpleSoft-Light.woff2") format("woff2");
}

@font-face {
  font-family: "ampleRegular";
  src: local("ampleRegular"), url("../assets/fonts/AmpleSoft.woff2") format("woff2");
}

@font-face {
  font-family: "ampleMedium";
  src: local("ampleMedium"), url("../assets/fonts/AmpleSoft-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "ampleBold";
  src: local("ampleBold"), url("../assets/fonts/AmpleSoft-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "angelface";
  src: local("angelface"), url("../assets/fonts/Angelface.woff2") format("woff2");
}

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Fira Sans", monospace;
}

*::-webkit-scrollbar {
  width: 10px !important;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 999999px !important;
}

*::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 999999px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  z-index: 1 !important;
}

button {
  border: none;
  outline: none;
}

#root {
  max-width: 100%;
  overflow: hidden;
}

.scroll {
  overflow: hidden;
  overflow-y: auto;
  scroll-margin-top: 50px;
}

.scroll0::-webkit-scrollbar {
  width: 0px !important;
}

.scroll-thin::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}

.text__ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rotate__180deg {
  transition: ease-in-out 400ms !important;
  transform: rotateZ(-180deg);
}

.rotate__0deg {
  transition: ease-in-out 400ms !important;
  transform: rotateZ(0deg);
}

.svg__fill--normal>path,
.svg__fill--normal>svg>path {
  fill: #324440;
}

.svg__fill--gray>path,
.svg__fill--gray>svg>path {
  fill: #999999;
}

.svg__fill--light>path,
.svg__fill--light>svg>path {
  fill: #f1f1f1;
}

.svg__fill--none>path,
.svg__fill--none>svg>path {
  fill: #cbcbcb;
}

.svg__fill--success>path,
.svg__fill--success>svg>path {
  fill: #57ce71;
}

.svg__fill--alert>path,
.svg__fill--alert>svg>path {
  fill: #ff9900;
}

.svg__fill--danger>path,
.svg__fill--danger>svg>path {
  fill: #ff5c52;
}

.svg__fill--info>path,
.svg__fill--info>svg>path {
  fill: #62b8ae;
}

.animation__fadeIn {
  animation: fadeIn 400ms forwards;
}

.animation__fadeOut {
  animation: fadeOut 400ms forwards;
}

.animation__hideOnTop {
  animation: hideOnTop 400ms forwards;
}

.animation__showOnTop {
  animation: showOnTop 400ms forwards;
}

.container__full {
  width: 100%;
  height: 100%;
  position: relative;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    display: none;
  }

  25% {
    display: initial;
  }

  100% {
    opacity: 1;
    display: initial;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    display: initial;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes hideOnTop {
  0% {
    display: initial;
    transform: translateY(0);
  }

  100% {
    display: none;
    height: 0;
    transform: translateY(-100vh);
  }
}

@keyframes showOnTop {
  0% {
    display: none;
    transform: translateY(-100vh);
  }

  100% {
    display: initial;
    transform: translateY(0);
  }
}

.no-border {
  border: none !important;
}

.global__scrollbar--thumb {
  background: #999999;
  width: 10px;
  border-radius: 20px;
}

.global__scrollbar--track {
  position: absolute;
  width: 9px;
  /* colocar o mesmo espaçamento "top" no cálculo */
  height: calc(100% - (20px * 2));
  right: 0;
  top: 20px;
  border-radius: 20px;
  background: #f1f1f1;
}

@media print {
  @page {
    size: 1500px 2120px;
    margin: 0;
  }
}