.toolMode__option {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background: none;
  transition: all ease 400ms;
}

.toolMode__option:not(:first-child) {
  margin-left: 8px;
}

.toolMode__option-success {
  background: #57ce71;
}

.toolMode__option-success > svg {
  width: 20px;
}
.toolMode__option-success > svg path {
  fill: #fff;
}

.toolMode__option-normal {
  background: #ffffff;
}

.toolMode__option-normal > svg {
  width: 20px;
}
.toolMode__option-normal > svg path {
  fill: #000;
}

.isselected {
  background: #62b8ae !important;
}

.isselected > svg,
.isselected > svg > path {
  fill: #fff !important;
  stroke: #fff !important;
}

/* aqui */

.tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: #fff;
  color: #000;
  padding: 2px 5px;
  display: inline-block;
  border-radius: 5px;

  /* Position the tooltip */
  position: absolute;
  bottom: -25px;
  z-index: 1;
  white-space: nowrap;
  font-size: .8rem;
  font-family: "Fira Sans";
}

.toolMode__option:hover .tooltiptext {
  visibility: visible;
}

.toolMode__option > svg {
  width: 55%;
  height: 55%;
}