.newPassword__container {
  width: 1000px;
  margin-left: 100px;
  margin-top: 50px;
}

.newPassword__container--title {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 4rem;
  color: #ffffff;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.newPassword__container--subtitle {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.newPassword__container form, .button_container {
  width: 500px;
  margin-top: 50px;
}

.newPassword__container .button_voltar {
  width: 152px;
  margin-right: 20px;
}

.newPassword__container .button_continuar {
  width: 193px;
}

.newPassword__container .button_começar {
  width: 193px;
  margin-top: 63px;
}