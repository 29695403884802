.templateDataManager {
    background: #fff;
    border-radius: 20px;
    width: auto;
    height: calc(100% - 60px);
    margin: 30px;
    padding: 50px;
    display: flex;
    flex-direction: column;
}

.templateDataManager h2 {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    color: #4d4d4d;
    margin: 0;
}

.templateDataManager__container {
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.templateDataManager__container div {
    width: 100%;
}

.templateDataManager__container--item {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.templateDataManager__container--item h2 {
    font-family: "Fira Sans";
    font-size: 18px;
    font-weight: 600;
    line-height: 21.6px;
    text-align: left;
    color: #4D4D4D;
}

.templateDataManager__container--container {
    display: flex;
    justify-content: space-between;
    padding: 13px 20px;
    border: 2px solid #F1F1F1;
    background: #FFFFFF;
    border-radius: 10px;
    cursor: pointer;
}

.templateDataManager__container--container span {
    font-family: "Fira Sans";
    font-size: 22px;
    font-weight: 600;
    line-height: 26.4px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-align: left;
    color: #4D4D4D;
}

.templateDataManager__container--container .icon {
    transform: rotateY(180deg);
}