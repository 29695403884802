.createTask_header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 40px;
}

.createTask_header h2 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  color: #4d4d4d;
}

.createTask_header_buttonsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.createTask_chipsWrapper {
  display: flex;
  gap: 10px;
}

.createTask_cardsWrapper {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.paginator-divider {
  border-top: 1px solid #f1f1f1;
  padding-top: 40px;
}
