.footer {
    background: var(--dark-80);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 25px 0;
    margin-top: 5vh;
  }
  
  .footer .social-inline {
    display: flex;
    align-items: center;
    /* gap: 16px; */
    margin-top: 25px;
  }
  
  .footer .social-inline #icon {
    color: var(--white-10);
    margin-right: 16px;
  }
  
  .footer .social-inline #icon:first-child {
    font-size: 1.5rem;
  }
  
  .footer .social-inline #icon:nth-child(2) {
    font-size: 1.35rem;
  }
  
  .footer .social-inline #icon:nth-child(3) {
    font-size: 1.6rem;
  }

  @media only screen and (max-width: 450px) {
    .footer{
      padding: 8vh 0;
    }
    .footer img{
      height: 11vh;
    }
  }