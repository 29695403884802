.modalerror__button--redirect {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalerror__button--redirect > button {
  background: #62b8ae;
  border-radius: 32px;
  padding: 10px 40px;

  font-family: "Fira Sans";
  font-weight: 600;
  font-size: 22px;
  font-variant: small-caps;
  text-transform: uppercase;
  color: #FFFFFF;
  /* margin-top: 30px; */
  /* margin-bottom: 50px; */
}
