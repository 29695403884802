.filterpolygons__container > div:not(:first-child) {
  margin-top: 10px;
}

.filterpolygons__owner--container {
  background: #f1f1f1;
  border-radius: 10px;
  padding: 0 20px;
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.filterpolygons__owner--container > small {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #999999;
  text-transform: uppercase;
  cursor: default;
}

.filterpolygons__owner--container > h6 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #4d4d4d;
  text-transform: uppercase;
  margin: 0;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
}
