.close-button {
    border: solid 2px var(--gray-40);
    border-radius: 25px;
    background-color: transparent;
    width:50px;
    height: 50px;
    font-weight: 600;
  }
  
  .close-button > svg {
    width: 24px !important;
    height: 24px !important;
  }