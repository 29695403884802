.containerTable_container--title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.containerTable_container--title > h2 {
  font-family: "Fira Sans", monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 1.5625rem;
  color: #4d4d4d;
}

.containerTable_container--title > button {
  background: none;
  width: 18px;
  height: 18px;
  outline: none;
  border: none;
  margin-bottom: 10px;
}

.containerTable_container--title > button > svg {
  font-size: 1.4rem;
  stroke: #62b8ae;
}
