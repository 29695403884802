.sbs__content--main {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.sbs__container--steps {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.contentStep__main {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  border: solid 1px var(--gray-40);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: var(--gray-110);
}

.contentStep__main > strong {
  font-family: Roboto, sans-serif;
}

.contentStep__main--active {
  background: var(--green-0100);
  border: solid 1px var(--green-0100);
  color: var(--white-10) !important;
}

.contentStep__main--skiped {
  background: var(--gray-40);
  border: solid 1px var(--gray-40);
  color: var(--white-10) !important;
}

.sbs__main--title {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 60px;
  width: 270px;
  height: 100%;
  cursor: pointer;
  background: none;
  border: none;
}

.sbs__main--title > strong {
  font-family: Roboto, sans-serif;
  font-weight: 700;
  color: var(--gray-110);
  font-size: 1.12rem;
}

.sbs__main--title > span {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: var(--gray-85);
  font-size: 0.87rem;
  margin-top: 5px;
}

.subStep__verticalLine {
  width: 1px;
  height: 50px;
  background: var(--gray-40);
}

.teste {
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.26s ease;
}
.te {
  transform: scaleY(1);
}
