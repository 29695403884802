.textInputIcon-label__container {
  position: relative;
  height: 51px;
  display: flex;
  align-items: center;
  border: solid 1px var(--gray-40);
  border-radius: 10px;
}

.textInputIcon-label__container-disabled {
  position: relative;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #f1f1f1;
  border: none;
}

.textInputIcon {
  width: auto;
  background: none;
  outline: none;
  border: none;
  border-radius: 0px;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  padding-left: 25px !important;
}
.rhf__prefix--label {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #4d4d4d;
}

.textInputIcon-icon {
  width: 50px;
}

@keyframes bounce {
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(5px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-5px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(4px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-4px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(2px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-2px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
}
