.footer__EditOnboarding {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 90px;
  padding-top: 40px;
}

.footer__formEditOnboarding {
  display: flex;
}

.footer__formEditOnboarding > button {
  margin-right: 12px;
  width: 150px;
}

.delete__button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: #ff6161;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.delete__button-readValues {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: #ff6161;
  width: 50px;
  height: 50px;
  cursor: pointer;
  position: fixed;
  right: 120px;
  bottom: 80px;
}

.delete__button > svg,
.delete__button-readValues > svg {
  width: 24px;
  height: 24px;
}

.delete__button > svg > path,
.delete__button-readValues > svg > path {
  fill: #ffffff;
}
