.uploadFileKMl--container {
  width: 100%;
  height: 100%;
  padding-left: 51px;
  transform: translateY(-35px);
}

.uploadFileKMl--container h6 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  color: #4d4d4d;
}

.uploadFileKMl--container span {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #ff6161;
  display: block;
  margin-bottom: 10px !important;
}

.uploadFileKMl--container .uploadFileKMl__container {
  margin-top: 20px;
}

.uploadFileKMl--kml {
  width: 400px;
  height: 300px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.uploadFileKMl--kml > img {
  width: 400px;
  height: 300px;
}

.gm-control {
  display: none;
}

.uploadFileKMl--buttonEdit {
  position: absolute;
  width: 52px;
  height: 52px;
  bottom: 20px;
  right: 20px;
  background: #62b8ae;
  border-radius: 32px;
}

.uploadFileKMl--buttonEdit svg {
  width: 18px;
  height: 18px;
}
.uploadFileKMl--buttonEdit svg path {
  fill: #fff;
}
.uploadFileKMl__button--namekml {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  height: 52px;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 25px;
  padding: 14px 20px;
  margin-top: 20px;
  width: 247px;
}

.uploadFileKMl__button--namekml h6 {
  margin: 0;
  padding: 0;
  margin-right: 10px;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #4d4d4d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.uploadFileKMl__button--namekml > button {
  background: none;
}

.uploadFileKMl__button--namekml > button svg {
  width: 15px;
  height: 15px;
}
.uploadFileKMl__button--namekml > button svg path {
  fill: #ff6161;
}

.gm-bundled-control-on-bottom {
  margin-right: 30px !important;
  margin-bottom: 30px !important;
}

.uploadFileKMl__container {
  width: 100%;
  height: auto;
}

.uploadFileKMl__container > label {
  width: 100%;
  height: 52px;
  background: #62b8ae;
  border-radius: 32px;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media only screen and (max-width: 1366px) {
  .uploadFileKMl__container > label {
    padding: 10px 10px;
  }
}

.uploadFileKMl__container > label > strong {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  margin-left: 13px;
}

.uploadFileKMl__container > input {
  display: none;
}

.fileUploadKml__header--modal {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  padding: 30px 30px 0 30px;
  top: 0;
  left: 0;
}

.fileUploadKml__header--modal .uploadFileKMl__button--namekml {
  margin: 0;
}

.fileUploadKml__header--modal > button {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #ffffff;
  border: 2px solid #f1f1f1;
}

.fileUploadKml__header--modal > button > svg {
  height: 18px;
  width: 18px;
}

.fileUploadKml__footer--modal {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  padding: 0px 30px 30px 30px;
  bottom: 0;
  left: 0;
}

.fileUploadKml__footer--modal-controlls h6 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  text-align: left;
  letter-spacing: 0.03em;
  font-variant: small-caps;
  text-transform: uppercase;
  color: #ffffff;
}

.fileUploadKml__footer--modal-controlls button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #e5e5e5;
}

.fileUploadKml__footer--modal-controlls button:not(:last-child) {
  margin-right: 10px;
}

.fileUploadKml__footer--modal-controlls .cancelButton > svg {
  width: 20px;
  height: 20px;
}

.fileUploadKml__footer--modal-controlls .cancelButton > svg > path {
  fill: #4d4d4d;
}

.fileUploadKml__footer--modal-controlls .confirmButton {
  background: #57ce71;
}

.fileUploadKml__footer--modal-controlls .confirmButton > svg {
  width: 20px;
  height: 20px;
}

.fileUploadKml__footer--modal-controlls .confirmButton > svg > path {
  fill: #ffffff;
}

.modalEdit__alert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.buttons-controll {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons-controll > .buttonwithchildren__container {
  display: flex;
  flex-direction: row;
  width: 142.5px;
}

.buttons-controll > .buttonwithchildren__container:first-child {
  margin-right: 10px;
}
