.grid__evaluators {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    height: calc(100vh - 270px);
    overflow: scroll;
    overflow-x: hidden;
    padding-right: 1rem;
}