  .container___buttonadd{
    margin-left: 4.5rem;
    margin-bottom: 2rem;
    padding-top: 1.5rem;
  }

  .container_retiro{
    padding-left: 20px;
  }

  .micro_area__drawarea--container {
    min-width: 400px;
    height: 300px;
    background: url("../../../../assets/png/genericMap.png");
    display: flex;
    align-items: flex-end;
    padding: 20px;
    transform: translateY(-40px);
    border-radius: 10px;
    overflow: hidden;
    margin-left: 30rem !important;
    margin-top: -16rem;
  }

.micro_area__drawarea--error {
  min-width: 400px;
  display: flex;
  align-items: flex-end;
  margin-left: 30rem !important;
}
