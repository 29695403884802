.TextInputWithUnit__unit {
  position: relative;
  z-index: -1;
  top: 39px;
  text-align: right;
  padding-right: 300px;
  width: 100%;
  color: rgb(153, 153, 153);
}

.TextInputWithUnit__container > .textInput__container > input {
  background-color: transparent;
}

.textInput__container > input:read-only {
  background: #f1f1f1;
  border: solid 1px transparent;
  color: #999999;
}
