.whatsapp-talk-container {
  position: fixed;
  bottom: 5vh;
  right: 5vh;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  z-index: 99;
}

.whatsapp-talk-container button {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  background: var(--green-100);
  margin-bottom: 8px;
  font-size: 1.1rem;
  cursor: pointer;
}

.whatsapp-talk-container button #icon {
  color: var(--white-10);
}

.whatsapp-talk-content {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 20px #00000029;
  background: var(--white-10);
}

.whatsapp-talk-content header {
  background: var(--green-100);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap: 8px; */
  padding: 8px 0;
  border-radius: 8px;
  cursor: pointer;
  color: var(--white-10);
}

.whatsapp-talk-content header strong {
  font-size: 0.9rem;
  font-weight: 400;
  font-family: ampleLight, sans-serif;
  margin-left: 8px;
}

.whatsapp-talk-content-hide,
.whatsapp-talk-content-show {
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 200px;
}

.whatsapp-talk-content-hide {
  min-width: 50px;
  max-height: 0px;
  transition: max-height 400ms ease-out;
}

.whatsapp-talk-content-show {
  max-height: 500px;
  min-width: 200px;
  transition: max-height 400ms ease-in;
}

.whatsapp-talk-main-content {
  width: 200px;
  padding: 16px 8px 8px 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.whatsapp-talk-main-content span {
  font-size: 0.8rem;
  text-align: center;
  font-family: ampleLight;
  width: 100%;
  color: var(--gray-100);
}

.whatsapp-talk-main-content .profile-pastejo {
  display: flex;
  /* gap: 8px; */
  margin-top: 16px;
}

.profile-pastejo .avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: var(--dark-80);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.profile-pastejo .avatar img {
  width: 90%;
  resize: both;
}

.avatar::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background: var(--green-90);
  position: absolute;
  right: 0px;
  bottom: 0px;
  border-radius: 50%;
  border: solid 2px #fff;
  z-index: 2;
}

.profile-pastejo .profile > span {
  font-size: 0.9rem;
  font-family: ampleRegular;
  color: var(--gray-100);
}

.profile-pastejo .profile h5 {
  font-size: 0.9rem;
  font-family: ampleBold;
  color: var(--gray-100);
}

.profile-pastejo .profile span:last-child {
  font-size: 0.7rem;
}

.whatsapp-talk-main-content .divider {
  width: 100%;
  height: 1px;
  background: #aeaeae;
  margin: 12px 0;
}

.whatsapp-talk-main-content .lets-talk {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.whatsapp-talk-main-content .lets-talk a {
  width: 100%;
  text-align: center;
  text-decoration: none;
  font-family: ampleRegular;
  color: var(--green-100);
  font-size: 0.9;
  margin-bottom: 8px;
}

.show-button-close {
  display: block !important;
}

@media only screen and (max-width: 450px) {
  .whatsapp-talk-container button {
    display: none;
  }
  .whatsapp-talk-content > main {
    transition: ease 600ms;
    width: 50px;
  }
  .whatsapp-talk-content strong {
    display: none;
  }
}

@media only screen and (min-width: 1500px) {
  .whatsapp-talk-container button {
    width: 64px;
    height: 64px;
    font-size: 2.3rem;
  }
  .whatsapp-talk-content {
    width: 300px;
  }
  .whatsapp-talk-content header,
  .whatsapp-talk-content header strong {
    font-size: 1.5rem;
  }
  .whatsapp-talk-main-content span {
    font-size: 1.2rem;
  }
  .profile-pastejo .avatar {
    width: 88px;
    height: 68px;
  }
  .avatar::after {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    background: var(--green-90);
    position: absolute;
    right: 0px;
    bottom: 0px;
    border-radius: 50%;
    border: solid 2px #fff;
    z-index: 2;
  }
  .profile-pastejo .profile span:first-child {
    font-size: 1.2rem;
  }
  .liskstart,
  .profile-pastejo .profile h5 {
    font-size: 1.3rem;
  }
  .profile-pastejo .profile span:last-child {
    font-size: 1rem;
  }
}
