.balloon {
  min-width: 73px;
  max-width: 100%;
  height: min-content;
  padding: 20px;
  border-radius: 20px;
  background: #f1f1f1;
  display: flex;
  justify-content: center;
}

.balloon > * {
  font-family: "Fira Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.pointed {
  border-top-left-radius: 0 !important;
}
