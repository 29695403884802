.itemSelected {
  display: flex;
  margin: 20px 0;
}

.itemSelected h3:first-child {
  margin-left: 0;
}

.itemSelected h3 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #999999;
  margin: 10px;
  text-transform: uppercase;
  cursor: pointer;
}

.delimiterItemSelected {
  width: 100%;
  height: 1px;
  display: block;
  background: #f1f1f1;
  border-radius: 1px;
  margin: 20px 0;
}

.itemSelectedBody {
  margin-left: -45px;
  max-height: calc(100vh - 510px);
  overflow: scroll;
  overflow-x: hidden;
}

.withoutTitle {
  min-height: calc(100vh - 350px);
}

.withoutButton {
  overflow: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 400px);
}

.footer-view__container>button {
  margin-right: 12px;
}

.footer-view__container #cancel,
.footer-view__container #save {
  width: 169px;
}

.bodyContent {
  height: calc(100vh - 440px);
}

.buttonsModal-controll {
  display: flex;
  width: 400px;
  justify-content: center;
  align-items: center;
}

.buttonsModal-controll>.buttonwithchildren__container {
  display: flex;
  margin: 50px;
  flex-direction: row;
  width: 18px;
}

.buttonsModal-controll>.buttonwithchildren__container:first-child {
  margin-right: 10px;
}