.product-label {
  font-family: "Fira Sans";
  font-size: 25px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #4d4d4d;
}

.fertilization-counter-container {
  margin-top: 20px;
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.fertilization-counter-container .product-label {
  margin-top: 20px;
}

.fertilization-counter-container .label__only--inline > span {
  text-transform: none;
}
