.spraying-counter-container {
  margin-top: 20px;
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.spraying-counter-container .label__only--inline > span {
    text-transform: none;
  }