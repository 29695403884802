.drawpolygon__container {
  width: 100vw;
  height: 100vh;
}

.drawpolygon__container > .drawfarm__label--container {
  width: auto;
  height: 52px;
}

.drawpolygon__container .infoBox {
  width: auto !important;
}

.drawpolygon__container .infoBox .drawfarm__label--container {
  padding: 20px;
}

.drawpolygon__container .infoBox .drawfarm__label--container h3 {
  padding: 0;
  margin: 0;
}

.drawpolygon__container textarea {
  display: none;
}
