.DrawOnMap__section--view-results {
  width: 500px;
  height: 300px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  z-index: 0;
}

.DrawOnMap__aside--wrapper-controlls {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 900;
}

.DrawOnMap__aside--container-controlls {
  width: min-content;
  height: 100%;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 20px;
}

.DrawOnMap__aside--pen-icon,
.DrawOnMap__aside--close-icon {
  width: 20px !important;
  height: 20px !important;
  resize: both !important;
}

.DrawOnMap__aside--pen-icon > path,
.DrawOnMap__aside--close-icon > path {
  fill: #ffffff !important;
}

.leaflet-right {
  display: none;
}

.leaflet-control,
.leaflet-draw-toolbar {
  display: flex;
  padding: 0 !important;
  margin: 0 !important;
}

.leaflet-draw-toolbar {
  border: none !important;

  /* border-radius: 50% !important; */
  overflow: hidden;
}

.leaflet-draw-toolbar > a {
  background-size: 20px 20px !important;
  background-position: center !important;
  border: none !important;
  width: 45px !important;
  height: 45px !important;
  position: relative;
  border-radius: 50% !important;
}

.leaflet-draw-edit-remove {
  background-image: url("../../../../assets/svg/icons/trash.svg") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;
  background-color: #ffffff !important;
}

.leaflet-draw-edit-edit {
  background-image: url("../../../../assets/svg/icons/penEdit.svg") !important;
}

.leaflet-draw-draw-polygon {
  background-image: url("../../../../assets/svg/icons/minGraph.svg") !important;
}

.DrawOnMap__section--view-default {
  width: 100%;
  height: 100%;
  background-image: url('../../../../assets/png/genericMap.png');
}
