.textInput-label__container {
  position: relative;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #365951;
  border-radius: 20px;
}

.textInput-label__container > button {
  width: 30px;
  height: 30px;
  background: transparent;
  z-index: 2;
  margin-right: 20px;
}

.textInput-label__content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.textInput-label__content > label {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  position: absolute;
  bottom: 2rem;
  padding: 0px 20px;
}

.textInput-label__content > input {
  height: 100%;
  min-height: 1.2rem;
  width: 100%;
  background: none;
  outline: none;
  border: none;
  border-radius: 0px;
  /* padding: 0 !important; */
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  color: #ffffff;
  position: absolute;
  padding-top: 25px !important;
  padding-bottom: 0px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.textInput-label__content > .inputpassword {
  color: #ffffff !important;
}

.inputStatusError {
  border: 2px solid #ff6161;
  animation-name: bounce;
  animation-duration: 0.5s;
  animation-delay: 0.25s;
}

.inputStatusError .textInput-label__content > label {
  color: #ff6161;
}

.inputStatusSuccess {
  border: 2px solid #57ce71;
}

.inputStatusSuccess .textInput-label__content > label {
  color: #57ce71;
}

.inputStatusNormal {
  border: 2px solid transparent;
}

.inputStatusNormal .textInput-label__content > label {
  color: #62b8ae;
}

.textInputwithlabel__error {
  display: block;
  position: relative;
  color: #FFFFFF;;
  font-family: "Fira Sans", monospace;
  font-size: 0.875rem;
  padding: 0 0;
  padding-top: 20px;
  font-style: normal;
  font-weight: 600;
}

.inputpassword {
  padding: 0 !important;
  margin: 0 !important;
}

@keyframes bounce {
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(5px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-5px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(4px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-4px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(2px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-2px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
}
