.createuser__container {
  width: 500px;
  margin-left: 50px;
}

.createuser__container h1 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 43px;
  color: #4d4d4d;
  margin: 0;
  margin-bottom: 50px;
}

.onboarding-main-content {
  margin-bottom: 20px;
  margin-top: 30px;
}

.createuser__alertModalBody {
  margin: 20px;
}

.createuser__alertModalBody h3 {
  margin-top: 10px !important;
}
