.pasturesUnavailable__title--container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .pasturesUnavailable_container {
    width: 500px;
  }

  .pasturesUnavailable_content {
    width: 500px;
  }

  .pasturesUnavailable__form {
    min-width: 500px;
  }
  
  .pasturesUnavailable__textinput--container {
    margin-top: 20px;
  }


  .pasturesUnavailable__title--container button {
    background: transparent;
    border: none;
    outline: none;
  }

  .pasturesUnavailable__textinput--container label {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.375rem;
    color: #4d4d4d;
    margin-bottom: 10px;
  }

  .pasturesUnavailable__divider {
    width: 100%;
    height: 2px;
    background: #f1f1f1;
    margin: 20px 0;
  }