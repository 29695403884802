.paginator {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.grid__infos {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  height: calc(100vh - 410px);
}

.filter__content {
  display: flex;
  margin-bottom: 20px;
  gap: 10px;
}

/* @media (max-width: 2000px) {
  .grid__infos {
    height: 100%;
  }

  .filter__content {
    margin-bottom: 15px;
  }
} */

.children__content {
  height: 100%;
  overflow: auto;
}

.children__content>main>.animalcategory__container,
.children__content>main>.createDeathGoal__container,
.children__content>main>.createBirthGoal__container {
  padding: 0 !important;
}

.children__content>main>.animalcategory__container>.animacategory__content h1 {
  margin-bottom: 0;
}

.children__content>main>.animalcategory__container>.animacategory__content>.evolution__container h2 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.02em;
}