.createassortment__container {
  width: 550px;
  overflow: hidden;
  padding: 30px 50px 20px 50px;
}

.createassortment__container > h1 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  color: #4d4d4d;
  margin-bottom: 50px;
}

.createassortment__container--input > label {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 22px;
  color: #4d4d4d;
  margin-bottom: 10px;
}

/* No form damos margem no container */
.createassortment__form--container {
  margin: 20px 0;
}

/* Na seção de form damos margem apartir do segundo input */
.createassortment__form--container .createassortment__container--input:not(:first-child) {
  margin: 20px 0;
}

.createassortment__container--address {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.createassortment__container--address > .createassortment__container--input:first-child {
  margin: 0;
  width: 95px;
  margin-right: 10px;
}

.createassortment__container--address > .createassortment__container--input:last-child {
  width: 100%;
  margin: 0;

}

/* Este seletor funciona como o LIKE em SQL */
.createassortment__container--address
  > .createassortment__container--input:first-child
  .react-select
  div[class$="-control"] {
  padding-left: 10px;
  padding-right: 12px;  
}

/* Este seletor funciona como o LIKE em SQL */
.createassortment__container--address
  > .createassortment__container--input:first-child
  .react-select
  div[class$="-option"] {
  padding-left: 0; 
}
