.pasturemanagement__container {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 900;
  width: 280px;
}

.pasturemanagement__footerlegend--container {
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 400;
}

.pasturemanagement__footerlegend--container>h6 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.03em;
  font-variant: small-caps;
  color: #ffffff;
  text-transform: uppercase;
}

.pasturemanagement__footerlegend--content {
  display: flex;
}

.pasturemanagement__footerlegend--content>div:not(:first-child) {
  margin-left: 10px;
}

.pasturemanagement__days--bar {
  margin-top: 10px;
  width: 450px;
  height: 20px;
  border: 2px solid #ffffff;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  background: linear-gradient(90deg,
      #0157FF 0%,
      #FF0000 100%);
  margin-right: -1px;
}

.pasturemanagement--bar {
  margin-top: 10px;
  width: 450px;
  height: 20px;
  border: 2px solid #ffffff;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  background: linear-gradient(90deg,
      #FF6161 0%,
      #57CE71 100%);
  margin-right: -1px;
}

.pasturemanagement--containerlegend {
  width: 100%;
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}

.pasturemanagement--containerlegend>span {
  font-family: "Fira Sans", monospace;
  font-weight: 500;
  font-size: 1rem;
  color: #ffffff;
  margin-top: 5px;
}