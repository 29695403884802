.textinput__weight--error {
  font-size: 0.8rem;
  color: red;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}
