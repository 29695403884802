.containerHeightPasture {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.textHeight {
  color: #4D4D4D;
  font-family: "Fira Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  font-variant: all-small-caps;
  letter-spacing: 1.1px;
}

.buttonHeight {
  width: 100%;
  height: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 40px;
  gap: 10px;
}

.heightPress { 
  background: #57CE71;
  border-radius: 25px;
}

.heightPress * { 
  color: #FFF;
  fill: #FFF;
}

.heightNotPress {
  border: 2px solid #999;
  background: #FFF;
  border-radius: 25px;
}

.heightNotPress * {
  color: #4D4D4D;
  fill: #4D4D4D;
}
