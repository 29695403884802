.EditAreas_section-container {
  width: 100%;
  height: 100%;
}

.EditAreas_container--form {
  width: 100%;
  height: calc(100% - 90px);
  overflow: auto;
  display: flex;
}

.EditAreas_container--form-left,
.EditAreas_container--form-right {
  min-width: 500px;
  max-width: 500px;
}

.EditAreas_container--form-right {
  width: 100%;
  height: 100%;
  padding-left: 56px;
}

.EditAreas_container--form-right ul {
  width: 400px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0;
  grid-gap: 10px;
  margin-top: 20px;
}

.EditAreas_container--form-right ul li {
  list-style: none;
  width: 100%;
}

.EditAreas__button--alter-initials {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #62b8ae;
  background: transparent;
  margin-top: 10px;
}

.EditAreas__button--alter-initials[disabled] {
  cursor: no-drop;
}

.EditAreas_modalalert-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.EditAreas_modalalert-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
