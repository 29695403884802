.body-task {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px;
  align-items: center;
  gap: 30px;
}

.header-taskModal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-taskModal h2 {
  color: #4D4D4D;
  text-align: center;
  font-family: 'Fira Sans';
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}

.header-taskModal span {
  text-align: center;
  color: #999;
  text-align: center;
  font-family: 'Fira Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.header-taskModal button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.task-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
}

.task-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.task-wrapper>h2 {
  font-family: Fira Sans;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #4d4d4d;
}

.task-label {
  color: #4D4D4D;
  font-family: 'Fira Sans';
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
}

.task-wrapper>svg {
  height: 50px;
}

.task-wrapper>svg>path,
.task-wrapper>svg>rect {
  fill: #4d4d4d;
}

.task-designation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.filters-container {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
}

.filters-wrap {
  flex-wrap: wrap;
}

.filters-column {
  flex-direction: column;
  width: 100%;
}

.filters-dropdown {
  width: 270px;
}

.filters-dropdown__full-width {
  width: 100% !important;
}

.filters-dropdown>.dropdownSearch,
.filters-dropdown>.dropdownfilter {
  position: relative;
  height: auto;
  border: 2px solid #e5e5e5;
  border-radius: 10px;
}

.dropdown-margins {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.filters-dropdown>.dropdownSearch .dropdownSeach__listResult,
.filters-dropdown>.dropdownfilter .dropdownfilter__menu--content {
  position: absolute;
  z-index: 1000;
  width: calc(100% + 4px);
  left: 1px;
  top: 48px;
  margin: -3px;
  border: 2px solid #e5e5e5;
  border-top: none;
}

.filters-dropdown>.dropdownSearch svg {
  width: 24px;
  height: 24px;
  transform: scale(1);
}

.filters-dropdown>.dropdownSearch svg>path {
  fill: #999;
}

.pasture-container {
  width: 550px;
  height: 233px;
  background-color: #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px !important;
  margin-bottom: 30px;
}

.pasture-container>* {
  cursor: pointer !important;
  border-radius: 10px;
}

.pasture-container .leaflet-control {
  visibility: hidden;
}

.pasture-container>svg>path {
  fill: #999;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.task-border {
  width: 100%;
  height: 377px;
  padding: 20px 15px 20px 40px;
  border-radius: 10px;
  border: 2px solid #e5e5e5;
  margin-bottom: 30px;
  z-index: 1005;
  overflow: hidden;
}

.task-intrinsecs {
  z-index: 905;
  padding: 20px 15px 20px 0px;
  max-height: 377px;
  height: 324px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.task-intrinsecs .dropdownfilter {
  border: 2px solid #e5e5e5;
  border-radius: 20px;
  width: 100% !important;
}

.task-intrinsecs .dropdownfilter>button {
  border-radius: 20px;
}

.task-intrinsecs .dropdownfilter .dropdownfilter__menu--content {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.balloon-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}

.task-button-container {
  height: 50px;
  width: 100%;
}

.detached-task_details-label {
  font-family: "Fira Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #4d4d4d;
}

.detached-task_details-textarea {
  margin-top: 10px;
  width: 550px;
  height: 201px;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid #e5e5e5;
  margin-bottom: 30px;
}

.task-intrinsecs .dropdownfilter button>h3,
.task-intrinsecs .dropdownfilter button>small,
.task-intrinsecs .dropdownfilter li>button {
  font-family: "Fira Sans";
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.inputCreateTask {
  position: relative;
  height: auto;
  border: 2px solid #e5e5e5;
  border-radius: 10px;
  padding: 0 20px;
  outline: none;

  overflow: hidden;
  color: #4D4D4D;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Fira Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 80%;
  font-variant: all-small-caps;
  letter-spacing: 0.4px;

  width: 268px;
}

.inputCreateTask:disabled {
  background: #F1F1F1;
}