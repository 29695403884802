.error__modal-header {
  position: absolute;
  top: 20px;
  right: 20px;
}

.error__modal-message {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  gap: 10px;
}

.error__modal-message > h2 {
  font-family: "Fira Sans";
  font-size: 36px;
  font-weight: 600;
  line-height: 43px;
  letter-spacing: 0px;
  text-align: center;
  color: #4d4d4d;
  margin: 0;
}

.error__modal-message > span {
  font-family: "Fira Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: #999999;
}

.error__modal-button-container {
  margin-top: 30px;
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.error__modal-button-container .buttononlytitle--info {
  background-color: #ff6161;
  border: none;
}
