.labelfarm1x__container {
  /* width: 100px; */
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.labelfarm1x__container > svg {
  transform: scale(1.5);
}

.labelfarm1x__container > svg path {
  fill: #fff;
}

.labelfarm1x__initials--container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 10px;
  height: 52px;
  background: #4d4d4d;
  opacity: 0.9;
  border: 1px solid #999999;
  border-radius: 10px;
  margin-top: 15px;
}

.labelfarm1x__initials--container > h6 {
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
