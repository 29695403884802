.subtask-container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.subtask-container .balloon-container,
.subtask-container .buttonwithchildren__container {
  margin-top: 20px;
}

.batches-modal-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-self: flex-start;
}

/* .modalDrawMaps .rodal {
  position: fixed;
  top: 0;
  left: 0;
  right: unset;
  bottom: unset;
  margin: auto;
}

.modalDrawMaps .rodal-dialog {
  margin: 0;
} */