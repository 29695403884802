.dropdownfilter > button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #ffffff;
  border-radius: 9px;
  width: 100%;
  padding: 0 20px;
  min-height: 50px;
  transition: all ease 300ms;
}

.dropdownfilter > .menuIsOpen {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.dropdownfilter > button > small {
  display: block;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  font-variant: small-caps;
  color: #999999;
  margin-right: 10px;
  white-space: nowrap;
  text-transform: uppercase;
}

.dropdownfilter > button > h3 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #4d4d4d;
  margin: 0;
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdownfilter > button > .icon {
  margin-left: 4px;
}

.dropdownfilter__menu--container {
  background: #ffffff;
  cursor: pointer;
  padding: 10px 0;
  border-top: solid 2px #f1f1f1;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  margin-bottom: 0;
}

.dropdownfilter__menu--container > li {
  list-style: none;
}

.dropdownfilter__menu--container > .isSeleted > button {
  color: #4d4d4d;
  font-weight: 600;
}

.dropdownfilter__menu--container > li > button {
  width: 100%;
  padding: 0 20px;
  text-align: left;
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-transform: uppercase;
  color: #999999;
  background: transparent;
}

.dropdownfilter__menu--container > li:not(:last-child) {
  padding-bottom: 10px;
}

.dropdownfilter__menu--content {
  max-height: 300px;
  background: #fff;
  overflow: hidden;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
}

.dropdownfilter__menu--container {
  overflow-y: auto;
  max-height: 300px;
}
