.sliderContainer {
  margin-top: 40px;
  height: 20px;
  padding: 0 10px 0 10px;
}

.textSlider {
  font-family: Fira Sans !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #ffffff !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
  margin: -30px 0;
  padding-top: 5px;
}

.lineGray {
  position: absolute;
  width: 100%;
  height: 5px;
  margin-top: 4px;
  cursor: pointer;
  background: #4d4d4d;
}

.lineBlue {
  position: absolute;
  width: 100%;
  height: 5px;
  margin-top: 4px;
  cursor: pointer;
  background: #62b8ae;
}

.slider-ticks {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pontoInicioChuva {
  border: 4px solid #62b8ae;
}

.pontoInicioSeca {
  border: 4px solid #4d4d4d;
}

.avisoInicioChuva {
  background: #62b8ae;
  border-radius: 20px;
}

.avisoInicioSeca {
  background: #4d4d4d;
  border-radius: 20px;
}

.barAposInicioChuva {
  margin-top: 4px;
  background: #62b8ae;
}

.barAposInicioSeca {
  margin-top: 4px;
  background: #4d4d4d;
}

.divisaoMeses {
  position: absolute;
  margin-top: 4px;
  width: 2px;
  height: 5px;
  z-index: 10;
  background: #ffffff;
}

.listaMeses {
  position: absolute;
  margin-top: 22px;
  font-size: 10px;
  text-align: center;
}

.indicadorTempo {
  position: absolute;
  margin-left: -11px;
  margin-top: -6px;
  z-index: 2;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  box-sizing: border-box;
}

.tagPonto {
  position: relative;
  width: 115px;
  height: 27px;
  left: -50px;
  top: -5px;
  border-radius: 20px;
}
