.title_createProduct {
  color: #4D4D4D;
  font-family: "Fira Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.body_resume {
  border-radius: 10px;
  border: 2px solid #E5E5E5;
  overflow: hidden;
  width: 370px;
}

.formContainerProduct {
  display: flex;
}

.body_title {
  color: #FFF;
  font-family: "Fira Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.36px;
  background: #4D4D4D;
  padding: 20px;
  width: 185px;
}

.container_formResult {
  color: #4D4D4D;
  font-family: "Fira Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  padding: 17px 20px;
  width: 185px;
  border-bottom: 2px solid #E5E5E5;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.last {
  border: 0px;
}

.formLabel {
  margin: 20px 20px 0 0;
  color: #4D4D4D;
  font-family: "Fira Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.buttonsSubmit {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  width: 370px;
}

.formDeleteProduct {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 60px;
}

.formDeleteProduct .close {
  position: fixed;
  top: 20px;
  right: 20px;
}

.formDeleteProduct .infoModal {
  display: flex;
  flex-direction: column;
  gap: 10px;
}