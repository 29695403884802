.rodal-close {
  display: none !important;
}
.rodal-dialog {
  padding: 0;
}

.rodal{
  display: flex;
  align-items: center;
  justify-content: center;
}
