.paddocktime__container {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 900;
  width: 280px;
}

.paddocktime__footerlegend--container {
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 400;
}

.paddocktime__footerlegend--container>h6 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.03em;
  font-variant: small-caps;
  color: #ffffff;
  text-transform: uppercase;
}

.paddocktime__footerlegend--content {
  display: flex;
}

.paddocktime__footerlegend--content>div:not(:first-child) {
  margin-left: 10px;
}

.paddocktime__barnumber--bar1 {
  margin-top: 10px;
  width: 450px;
  height: 20px;
  border: 2px solid #ffffff;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  background: linear-gradient(90deg,
      #FFCA80 0%,
      #FF9501 50%,
      #FF6161 100%);
  margin-right: -1px;
}

.paddocktime__barnumber--bar2 {
  margin-top: 10px;
  width: 450px;
  height: 20px;
  border: 2px solid #ffffff;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  background: linear-gradient(90deg,
      #57CE71 0%,
      #61FFF6 50%,
      #7B61FF 100%);
  margin-right: -1px;
}

.paddocktime__barnumber--containerlegend {
  width: 100%;
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}

.paddocktime__barnumber--containerlegend>span {
  font-family: "Fira Sans", monospace;
  font-weight: 500;
  font-size: 1rem;
  color: #ffffff;
  margin-top: 5px;
}