.customPopup__container > .leaflet-popup-content-wrapper {
  padding: 0;
  background: none;
  box-shadow: none;
}

.customPopup__container .leaflet-popup-content {
  margin: 0;
  width: auto !important;
  box-shadow: none;
}

.customPopup__container > .leaflet-popup-tip-container {
  display: none;
}
