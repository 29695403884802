.chip-base__container {
  width: min-content;
  height: 52px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
}

.chip-base__container > svg {
  width: 27px;
  height: 25px;
  margin-right: 10px;
}

.chip-base__container > h3 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  white-space: nowrap;
  margin: 0;
}

/* COMPONENTE CHIP PRESSABLE*/
.chip-pressable__container {
  background: transparent;
}
