.subStep__container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.subStep__verticalLine {
  width: 1px;
  height: 50px;
  background: var(--gray-40);
}

.subStep__container--button {
  background: transparent;
  cursor: pointer;
  border: none;
}

.subStep__content {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  align-items: center;
}

.subStep__rounded--content {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: relative;
}

.subStep__border--visualized,
.subStep__border--advanced,
.subStep__border--nothing {
  border: solid 2px var(--gray-40);
  border-radius: 50%;
}

.subStep__border--unnecessary,
.subStep__border--skiped {
  border: solid 1px var(--gray-85);
  border-radius: 50%;
}

.subStep__title {
  position: absolute;
  right: 25px;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  width: 200px;
  text-align: right;
}

/* [INICIO] - Manipuladores de estado do titulo */
.subStep__title--nothing {
  color: var(--gray-40) !important;
}

.subStep__title--visualized,
.subStep__title--advanced {
  color: var(--gray-110) !important;
}

.subStep__border--unnecessary,
.subStep__title--skiped {
  color: var(--gray-85) !important;
}
/* [FIM] - Manipuladores de estado do titulo */

/* [INICIO] Manipuladores de estado para o container */
.subStep__rounded--nothing::after {
  content: none;
}

.subStep__rounded--visualized::after,
.subStep__rounded--advanced::after {
  content: "";
  position: absolute;
  top: 5%;
  left: 5%;
  display: block;
  width: 80%;
  height: 80%;
  background: var(--green-0100);
  border-radius: 50%;
}

.subStep__border--unnecessary::after,
.subStep__rounded--skiped::after {
  content: "";
  position: absolute;
  top: 5%;
  left: 5%;
  display: block;
  width: 80%;
  height: 80%;
  background: var(--gray-85);
  border-radius: 50%;
}
/* [FIM] Manipuladores de estado para o container */
