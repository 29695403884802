.createDeathGoal__container {
    padding: 30px 0 50px 50px;
}

.createDeathGoal__container h1 {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 2.25rem;
    color: #4d4d4d;
}

.createDeathGoal__container {
    position: relative !important;
}

.createDeathGoal__container form > .buttononlytitle {
    width: 500px;
}
