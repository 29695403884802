.tableEdit__container {
  border-collapse: separate;
  border-spacing: 0;
  width: calc(300px + 200px + (12 * 112px));
}

.tableEdit__container thead {
  height: 62px;
  background: #4d4d4d;
  border: 1px solid #4d4d4d;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
  overflow: hidden !important;
}

.tableEdit__container thead tr th {
  width: 120px;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  height: 100%;
  text-transform: capitalize;
}

.tableEdit__container thead tr th:nth-child(1) {
  width: 100px;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background: #4d4d4d;
}

.tableEdit__container thead tr th:nth-child(2) {
  width: 220px;
  position: -webkit-sticky;
  position: sticky;
  left: 105px;
  background: #4d4d4d;
}
