.tableGoalItemEdit__table {
  border-collapse: collapse;
  border-radius: 1em;
  overflow: hidden;
  border-top: 1px solid #f1f1f1;
  width: 100%;
}

.tableGoalItemEdit__row {
  height: 64px;
  border-right: 1px solid #f1f1f1;
}

.tableGoalItemEdit__row td:nth-child(1),
.tableGoalItemEdit__row td:nth-child(3) {
  background-color: #4D4D4D;
  color: white;
  text-align: center;
  width: 100px;
}

.tableGoalItemEdit__row td:nth-child(2),
.tableGoalItemEdit__row td:nth-child(4) {
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  text-align: center;
  width: 100px;
}

.tableItemEdit__title{
  color: #4D4D4D;
  margin-top: 10px;
}


