.flytobounds__reactLeaflet {
  opacity: 0;
}



@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
