.dropdownCategory .react-select div[class$="-multiValue"] {
  background-color: #f1f1f1;
}

.dropdownCategory .react-select div[class$="-multiValue"] {
  background-color: #f1f1f1;
}

.dropdownCategory .react-select div[class$="-multiValue"] div:last-child {
  padding: 0;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 22px;
}

.dropdownCategory .react-select div[class$="-multiValue"] div:last-child:hover {
  background-color: #62b8ae;
}

.dropdownCategory .react-select div[class$="-control"]>div div[class$="-multiValue"]:not(:last-child)::after {
  line-height: 22px;
  margin-right: 5px;
}

.mapBatches {
  height: 300px;
  margin-top: 40px;
  margin-left: 0;
  width: 400px;
}

.body__formEditOnboarding--onlyRead {
  overflow-y: scroll;
  height: calc(100vh - 270px);
  position: relative;
  display: flex;
  gap: 60px;
}