.headerInfoEvaluation {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.dividerInfoEvoluation {
  height: 30px;
}

.titleInfoEvaluation {
  color: #4D4D4D;
  font-family: "Fira Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
  margin: 0;
}

.bodyInfoEvaluation {
  height: 450px;
  display: flex;
  gap: 30px;
}

.historyInfoEvaluation {
  border-radius: 10px;
  border: 2px solid #E5E5E5;
  padding: 40px;
  width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: hidden;
  overflow-y: scroll;
}

.contentInfoEvaluation {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 280px;
  height: 100%;
}

.statusInfoEvaluation {
  width: 100%;
  height: 70px;
  background: #4D4D4D;
  border-radius: 10px;
  padding: 14px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.statusInfoEvaluation>span {
  color: #FFF;
  font-family: "Fira Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
}

.stepsInfoEvaluation {
  display: flex;
  gap: 2px;
}

.stepCompleteInfoEvaluation {
  width: 50%;
  height: 4px;
  background: #57CE71;
  border-radius: 4px;
}

.containerCompleteInfoEvaluation {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contentInputContainerInfoEvaluation {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inputContainerCompleteInfoEvaluation {
  display: flex;
  gap: 10px;
  height: 50px;
  border-radius: 10px;
  background: #F1F1F1;
  border: 2px solid #E5E5E5;
  align-items: center;
  padding: 0 20px;
}

.inputContainerCompleteInfoEvaluation h1 {
  color: #999;
  font-family: "Fira Sans";
  font-size: 13px;
  font-weight: 400;
  font-variant: all-small-caps;
  line-height: normal;
  letter-spacing: 0.24px;
  margin: 0;
}

.inputContainerCompleteInfoEvaluation span {
  text-overflow: ellipsis;
  overflow: hidden;
  color: #4D4D4D;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Fira Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-variant: all-small-caps;
  letter-spacing: 0.4px;
}

.mapContainerInfoEvaluation {
  border-radius: 10px;
  border: 2px solid #E5E5E5;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.containerQuestion {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.iconViewTaskInfo * {
  color: #57CE71;
  fill: #57CE71;
}

.iconInitialTaskInfo * {
  fill: #4D4D4D;
}

.labelInitialTaskInfo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.labelInitialTaskInfo path {
  fill: #62b8ae;
}

.labelInitialTaskInfo span {
  color: #62b8ae;
  font-family: "Fira Sans";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.48px;
  line-height: 150%;
}