.dateContainer {
    display: flex;
    justify-content: space-between;
}

.dateFiltersContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.dateFiltersContainer span {
    color: #4D4D4D;
    font-family: "Fira Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.dateTitle {
    font-family: "Fira Sans";
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #4d4d4d;
    margin: 0;
}