.body__startEvaluation {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 500px;
}

.body__startEvaluation header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
}

.title__startEvaluation {
    color: #4D4D4D;
    text-align: center;
    font-family: "Fira Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.description__startEvaluation {
    color: #999;
    text-align: center;
    font-family: "Fira Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.closeButton__startEvaluation {
    position: absolute;
    top: 20px;
    right: 20px;
}