.paddocksmap__container--template {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
}

.paddocksmap__container--template > aside {
  margin: 0;
  padding: 0;
}

.paddocksmap__container--template > main {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.paddocksmap__container--template > main .leaflet-control-zoom,
.paddocksmap__container--template > main .leaflet-control-attribution {
  display: none;
}

.paddocksmap__container--template > main img[alt~="Google"] {
  display: none;
}
