.containerTable__content--item {
  display: flex;
  flex-direction: row;
  height: 62px;
  width: 100%;
  background: #ffffff;
  border-left: 1px solid #f1f1f1;
}

.containerTable__content--item:nth-child(2n) {
  background: #f1f1f1;
}

.containerTable__content--item:nth-child(2n) div:not(:last-child) {
  background: #f1f1f1;
  border-right: 1px solid #ffffff;
}

.containerTable__content--item > div {
  width: 33%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #f1f1f1;
  font-family: "Fira Sans", monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  padding: 20px;
  cursor: default;
}

.containerTable__content--item > div:not(:first-child) {
  border-left: 1px solid #f1f1f1;
}

.containerTable__content--item > div:nth-child(2) {
  width: 170px;
}

.containerTable__content--item > div:nth-child(2) {
  width: 230px;
}

.containerTable__content--item > div:nth-child(3) {
  width: 100px;
  padding: 0 auto;
  white-space: nowrap;
}
