.farm__container {
  width: 500px;
}

.farm__container:first-child {
  margin-top: 50px;
}

.farm__content {
  display: flex;
}

.farm__form {
  min-width: 500px;
  max-width: 500px;
}

.farm__title--container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.farm__title--container h3 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5625rem;
  letter-spacing: 0.02em;
  color: #4d4d4d;
}

.farm__title--container button {
  background: transparent;
  border: none;
  outline: none;
}

.farm__textinput--container {
  margin-top: 20px;
}

.farm__textinput--container label {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.375rem;
  color: #4d4d4d;
  margin-bottom: 10px;
}

.farm__textinput--container .farm__button--readonly {
  background: transparent;
  outline: none;
  border: none;
  margin-top: 10px;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  color: #62b8ae;
}

.farm__dropdown--address {
  display: flex;
  justify-content: space-between;
}

.farm__dropdown--address .farm__textinput--container:first-child {
  width: 90px;
}

.farm__dropdown--address .farm__textinput--container:last-child {  
  width: 400px;
}

.farm__textinput--container .noContent--msg {
  display: block;
  text-align: left;
  width: 100%;
  font-family: "Fira Sans";
}

.farm__divider {
  width: 100%;
  height: 2px;
  background: #f1f1f1;
  margin: 20px 0;
}

.farm__button--delete {
  margin-bottom: 10px;
}

.farm__dropdown--address
  .farm__textinput--container:last-child
  .react-select
  div[class$="-option"] {
  padding-left: 0 !important;
}

.farm__dropdown--address
  .farm__textinput--container:last-child
  .react-select
  div[class$="-control"] {
  padding-left: 10px !important;
}

.farm__textinput--container .slider-seasons {
  margin: 40px 0;
  margin-bottom: 100px;
}
