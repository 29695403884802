.body__formEditOnboarding {
  overflow-y: scroll;
  height: calc(100vh - 360px);
  position: relative;
  display: flex;
  gap: 60px;
}

.firstColumn {
  width: 500px;
}

.secondColumn {
  width: 450px;
  position: sticky;
  top: 10px;
  height: calc(100vh - (550px));
}

.secondColumn ul {
  width: 400px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0;
  grid-gap: 10px;
  margin-top: 20px;
}

.secondColumn ul li {
  list-style: none;
  width: 100%;
}