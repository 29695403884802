.hero-full-page {
  width: 100%;
  height: 100vh;
  position: relative;
}

.section-interface-container {
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
}

.hero-page {
  width: 100%;
  height: auto;
}

.container-900 {
  max-width: 900px;
  min-height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.container-700 {
  max-width: 700px;
  margin: 0 auto;
}

/*LANDING CONTAINER*/

.lannding-page {
  background: url("../../assets/png/landingPage.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.lannding-page::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.78) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 1;
}

.landing-container {
  max-width: 1100px;
  min-height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

/*LANDING MAIN*/

.landing-main {
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 2;
}

.landing-main > header {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.landing-main > header img {
  margin-right: 25px;
  width: 10vw;
}

.landing-main > header .social {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 8px; */
  font-size: 1.4rem;
  color: var(--white-10);
}
.landing-main > header .social #icon{
  margin-bottom: 16px;
}

.landing-main > header .social #icon:first-child {
  font-size: 1.7rem;
}

.landing-main > header .social #icon:nth-child(2) {
  font-size: 1.2rem;
}

.landing-main > header .social #icon:nth-child(3) {
  font-size: 1.4rem;
}

.landing-main footer {
  flex: 4;
}

.landing-main > footer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.landing-main > footer h6 {
  font-size: 1.5rem;
  color: var(--white-10);
  font-weight: 100;
  font-family: ample, sans-serif;
}

.landing-main > footer h3 {
  font-size: 3rem;
  width: 50%;
  color: var(--white-10);
  font-weight: 700;
  font-family: ampleBold, sans-serif;
}

.landing-main > footer .play-video {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /*  gap: 12px; */
  color: var(--white-10);
  margin-top: 25px;
}

.play-video button {
  background-image: url("../../assets/png/play-icon.png");
  background-size: contain;
  width: 64px;
  height: 64px;
  border: none;
  border-radius: 50%;
  transition: ease 1000ms;
  cursor: pointer;
  margin-right: 24px;
}

.play-video button #icon {
  width: 17px;
  height: 22px;
}

.play-video button:hover {
  background-image: url("../../assets/png/play-icon-hover.png");
  transition: ease 1000ms;
}

.play-video span {
  font-family: ampleLight, sans-serif;
}

/*LANDING FOOTER*/

.landing-container > footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0 36px 0;
}

.landing-container footer #chevron {
  font-size: 1.6rem;
  color: var(--green-80);
}

.interface-container {
  max-width: 1100px;
  min-height: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.datail-wave {
  position: absolute;
  bottom: -2vh;
  left: 0;
  width: 30vw;
  z-index: 50;
}

.interface-container main {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.interface-container aside {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5vw;
}

.interface-container aside img {
  width: 30vw;
}

.interface-container main h3 {
  font-family: ampleRegular, sans-serif;
  font-size: 2rem;
}

.interface-card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8%;
}

.interface-card h6 {
  flex: 2;
  font-size: 1rem;
  font-family: ampleBold, sans-serif;
  color: var(--dark-80);
}

.interface-card h6::after {
  content: "";
  display: block;
  height: 3px;
  width: 25%;
  background: var(--dark-80);
  border-radius: 2px;
}

.interface-card p {
  flex: 7;
  font-size: 0.9rem;
  font-family: ampleLight, sans-serif;
  color: var(--gray-100);
}

.section-tabs {
  background-image: url("../../assets/png/way.png");
  background-repeat: no-repeat;
  position: relative;
}

.section-tabs::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.76) 0%,
    rgba(18, 18, 18, 1) 30%,
    rgba(40, 40, 40, 0.9) 100%
  );
  z-index: 1;
}

/*VIDEO*/

.container-video {
  width: 100%;
  height: 80vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.container-video .video {
  width: 55%;
  height: 80%;
  background: var(--dark-90);
  display: flex;
  justify-content: center;
  align-items: center;
}

.video #icon {
  color: var(--white-10);
  font-size: 5rem;
}

/*INFORMATIONS*/

.information-container {
  max-width: 900px;
  margin: 0 auto;
  position: relative;
  height: auto;
  z-index: 2;
}

.information-container > h5 {
  font-family: ampleLight, sans-serif;
  color: var(--gray-80);
  font-size: 1.4rem;
  font-weight: 100;
}

.information-container h3 {
  font-family: ampleRegular, sans-serif;
  color: var(--gray-80);
  font-size: 2rem;
  font-weight: 400;
}

/* .tab {
  position: absolute;
  opacity: 0;
} */

.container-tabs {
  margin-top: 50px;
  position: relative;
  z-index: 2;
}

.container-tabs nav ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* gap: 100px; */
}

.container-tabs nav ul li {
  list-style: none;
  margin-right: 100px;
}

.container-tabs nav ul li span {
  cursor: pointer;
  color: var(--white-10);
  font-family: ampleBold, sans-serif;
  font-weight: 800;
  transition: ease 400ms;
}

.container-tabs nav ul li span:hover {
  color: var(--green-80);
}

.container-tabs nav ul li span::after {
  content: "";
  margin-top: 4px;
  display: block;
  width: 25%;
  height: 3px;
  background: var(--white-10);
}

.container-tabs nav ul li span:hover::after {
  background: var(--green-80);
}

/*a tab que estiver visivel recebe uma outra estilização*/

.tab-active {
  color: var(--green-80) !important;
}

.tab-active::after {
  background: var(--green-80) !important;
}

.container-tabs > main {
  position: relative;
}

/*SECTION CONTACT*/
#contact-us {
  justify-content: center;
  align-items: center;
  margin-top: 40vh;
}

#contact-us > h1 {
  font-family: ampleRegular, sans-serif;
  color: var(--white-10);
  font-size: 2.2rem;
  font-weight: 400;
  z-index: 2;
}

#contact-us > p {
  font-family: ampleLight, sans-serif;
  color: var(--white-10);
  font-size: 1rem;
  font-weight: 100;
  max-width: 80%;
  text-align: center;
  margin-top: 32px;
  z-index: 2;
}

#contact-us > a {
  padding: 16px 32px;
  border-radius: 12px;
  margin-top: 12px;
  border: none;
  background: var(--green-80);
  text-transform: uppercase;
  font-weight: 700;
  color: var(--white-10);
  font-size: 0.8rem;
  font-family: ampleBold;
  font-weight: 800;
  margin-top: 60px;
  cursor: pointer;
  z-index: 2;
  text-decoration: none;
}

.responsive-colapse {
  display: none;
}

/*CARDS CLIENTS*/

.clients {
  margin-top: 30vh;
  width: 100%;
  z-index: 10 !important;
}

.clients-title {
  max-width: 50%;
  margin: 0 auto;
}

.clients-title h6 {
  font-size: 1.3rem;
  text-align: center;
  font-family: ampleLight, sans-serif;
  color: var(--white-10);
  font-weight: 400;
  z-index: 20 !important;
}

.clients-title h4 {
  font-size: 1.6rem;
  text-align: center;
  font-family: ampleRegular, sans-serif;
  color: var(--white-10);
  margin-top: 8px;
  font-weight: 400;
}

.card-client-container {
  width: 100%;
  display: flex;
  position: relative;
  margin-top: 70px;
}

.card-client-container .card-client:first-child,
.card-client-container .card-client:last-child {
  position: absolute;
  z-index: 1;
  transform: scale(0.8);
  filter: brightness(0.9);
  opacity: 0.9;
}

.card-client-container .card-client:first-child {
  left: -50px;
  z-index: 2;
}

.card-client-container .card-client:last-child {
  right: -50px;
  z-index: 2;
}

.card-client-active {
  z-index: 3;
}

.card-client {
  width: 50%;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.4);
  border-radius: 22px;
  position: relative;
  margin: 50px auto;
  padding: 22px 32px;
  background: #fff;
}

.profile-client {
  width: 100px;
  height: 100px;
  background: #ddd;
  position: absolute;
  top: -50px;
  left: calc(50% - 50px);
  border-radius: 50%;
}

.comment-container p {
  text-align: center;
  font-family: ampleLight, sans-serif;
  color: var(--gray-100);
  font-size: 0.9rem;
  margin: 16px 0;
}

.comment-container div:last-child {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 32px;
}

.comment-container div:last-child img {
  transform: scaleX(-1);
}

.identity-client {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 12px;
}

.identity-client h3 {
  font-size: 4rem;
  font-family: angelface, sans-serif;
  font-weight: 400;
  color: var(--gray-100);
}

.identity-client h6 {
  font-family: ampleLight, sans-serif;
  color: var(--gray-100);
  font-weight: 400;
}

.card-clients-controller {
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap: 22px; */
}

.card-clients-controller button {
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background: var(--white-10);
  font-size: 1.2rem;
  margin-bottom: 70px;
  border: solid var(--gray-90) 1px;
  margin-left: 22px;
}

/*SECTION USE CASES*/

.use-cases {
  width: 100%;
  margin-top: 10vh;
}

.use-cases-container header {
  display: flex;
  align-items: center;
}

.use-cases-container header div:first-child {
  flex: 1;
}

.use-cases-container header div:first-child h6 {
  font-size: 1.5rem;
  color: var(--green-80);
  font-family: ampleBold, sans-serif;
}

.use-cases-container header div:first-child h3 {
  font-size: 1.7rem;
  color: var(--dark-80);
  font-family: ampleRegular, sans-serif;
  font-weight: 400;
  margin-top: 8px;
}

.use-cases-container header .map img {
  width: 11vw;
}

.use-cases-container main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.use-cases-card {
  flex: 1 1 33%;
  margin-top: 10vh;
}

.use-cases-card > div {
  width: 98%;
  height: 56px;
}

.use-cases-card > div img {
  height: 90%;
  resize: both;
}

.use-cases-card > h6 {
  max-width: 90%;
  font-size: 1rem;
  margin-top: 8px;
  font-family: ampleMedium, sans-serif;
  font-weight: 600;
  color: var(--dark-80);
}

.use-cases-card > p {
  max-width: 90%;
  margin-top: 12px;
  font-size: 0.8rem;
  font-family: ampleLight, sans-serif;
  font-weight: 100;
  color: var(--dark-80);
}

.use-cases-card > button {
  min-width: 77%;
  margin-top: 56px;
  padding: 16px 0;
  background: var(--dark-80);
  border: none;
  border-radius: 9px;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--white-10);
  font-family: ampleBold;
  letter-spacing: 1px;
  cursor: pointer;
}

@media only screen and (max-width: 450px) {
  .landing-main footer h6,
  .landing-main header {
    display: none;
  }

  .landing-main {
    display: flex;
    padding: 0 7vw;
  }

  .landing-main footer {
    justify-content: center;
    align-items: center;
  }

  .landing-main footer h3 {
    text-align: right;
    width: 95%;
    align-self: flex-end;
    font-size: 2rem !important;
  }

  .landing-main .play-video {
    align-self: flex-end;
    flex-direction: row-reverse;
  }

  .landing-main .play-video button {
    width: 3rem;
    height: 3rem;
    background: url("../../assets/png/play-icon-hover.png") no-repeat cover;
  }

  .section-interface-container {
    min-height: 100vh !important;
  }

  .landing-container > footer {
    z-index: 2;
  }

  .section-interface-container {
    padding-bottom: 40px;
  }

  .interface-container {
    flex-direction: column;
    padding: 0 7vw;
  }

  .interface-container aside {
    margin: 5vh 0;
  }

  .interface-container aside img {
    width: 60vw;
  }

  .interface-container .interface-card {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10vh;
  }

  .interface-container .interface-card h6 {
    margin-bottom: 25px;
    font-family: ampleRegular, sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
  }

  .interface-container .interface-card p {
    line-height: 2rem;
  }

  .section-tabs {
    padding: 0 7vw;
  }

  .section-tabs .container-video {
    height: auto;
    padding: 0;
    padding-bottom: 5vh;
  }

  .section-tabs .container-video .video {
    min-width: 100%;
    min-height: 80%;
  }

  .information-container h5 {
    margin-bottom: 25px;
  }

  .container-tabs nav ul {
    flex-direction: column;
  }

  .container-tabs {
    display: none;
  }

  .responsive-colapse {
    display: block;
  }

  .content-collapse {
    position: relative;
  }

  .content-tab {
    width: 100%;

    overflow: hidden;
  }

  .show-collapse,
  .hide-collapse {
    display: flex;
    flex-direction: column;
  }

  .show-collapse {
    min-height: 100vh;
    transition: all 400ms ease-in;
  }
  .hide-collapse {
    height: 0px;
    transition: height 400ms ease-out;
  }

  .information-container {
    height: auto;
  }

  #contact-us {
    margin: 0;
    margin-top: 10vh;
    padding: 0;
  }

  #contact-us > p {
    max-width: 100% !important;
    text-align: left;
    font-size: 1.4rem;
    font-family: ampleLight;
    color: var(--gray-80);
    font-weight: 100;
  }
  #contact-us > button {
    width: 100%;
    font-size: 1.1rem;
    font-family: ampleRegular;
    letter-spacing: 1px;
    font-weight: 500;
  }

  .clients {
    margin-top: 10vh;
  }

  .clients .clients-title {
    max-width: 100%;
  }

  .clients .clients-title h6 {
    font-size: 2rem;
  }

  .clients .clients-title h4 {
    font-size: 1rem;
    line-height: 2rem;
    margin-top: 2vh;
  }
  .card-client-container .card-client .profile-client {
    width: 75px;
    height: 75px;
    background: #ddd;
    position: absolute;
    top: -35px;
    left: calc(50% - 35px);
  }

  .comment-container > div img {
    width: 18px;
  }

  .comment-container {
    overflow: hidden;
  }
  .card-client {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .comment-container p {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .card-client-container .card-client {
    width: 70%;
  }

  .identity-client h3 {
    margin-top: 15px;
    font-size: 1.8rem !important;
  }

  .identity-client h6 {
    font-size: 0.5rem;
  }
  .card-clients-controller {
    padding-bottom: 0 !important;
    justify-content: flex-end;
    /* gap: 15px; */
  }

  .card-clients-controller button {
    width: 30px;
    z-index: 1;
    height: 30px;
    font-size: 1rem;
    margin-bottom: 25px;
    margin-left: 15px;
  }

  .use-cases .use-cases-container {
    padding: 0 7vw;
  }
  .use-cases .use-cases-container header .map {
    display: none;
  }

  .use-cases .use-cases-container main {
    flex-direction: column;
  }

  .use-cases-card h6 {
    font-size: 1.2rem;
  }

  .use-cases-card > div {
    margin: 5vh 0;
  }

  .use-cases-card > div img {
    height: 9vh;
  }

  .use-cases-card p {
    font-size: 1.1rem;
  }

  .use-cases-card button {
    width: 100%;
  }
}

@media only screen and (max-width: 580px) {
  #contact-us {
    margin: 0;
    margin-top: 10vh;
    padding: 0;
  }

  #contact-us > p {
    max-width: 100% !important;
    text-align: left;
    font-size: 1.4rem;
    font-family: ampleLight;
    color: var(--gray-80);
    font-weight: 100;
  }
  #contact-us > button {
    width: 100%;
    font-size: 1.1rem;
    font-family: ampleRegular;
    letter-spacing: 1px;
    font-weight: 500;
  }

  .clients {
    margin-top: 10vh;
  }

  .clients .clients-title {
    max-width: 100%;
  }

  .clients .clients-title h6 {
    font-size: 2rem;
  }

  .clients .clients-title h4 {
    font-size: 1rem;
    line-height: 2rem;
    margin-top: 2vh;
  }
  .card-client-container .card-client .profile-client {
    width: 75px;
    height: 75px;
    background: #ddd;
    position: absolute;
    top: -35px;
    left: calc(50% - 35px);
  }

  .comment-container > div img {
    width: 18px;
  }

  .comment-container {
    overflow: hidden;
  }
  .card-client {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .comment-container p {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .card-client-container .card-client {
    width: 70%;
  }

  .identity-client h3 {
    margin-top: 15px;
    font-size: 1.8rem !important;
  }

  .identity-client h6 {
    font-size: 0.5rem;
  }

  .use-cases-container{
    padding: 0 2vw;
  }

  .use-cases .use-cases-container header .map {
    display: none;
  }
}

@media only screen and (max-width: 1050px) {
  .landing-main {
    padding: 0 7vw;
  }

  .landing-main header img {
    width: 20vw;
  }

  .landing-main footer {
    justify-content: center;
    align-items: flex-start;
  }

  .landing-main footer h3 {
    width: 90% !important;
    margin-bottom: 5vh;
  }

  .section-interface-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .interface-container {
    flex-direction: column;
  }

  .interface-container aside img {
    width: 38vh;
  }

  .interface-container main {
    margin-top: 5vh;
    padding: 0 7vw;
  }

  .section-tabs {
    padding: 0 7vw;
  }

  .section-tabs .container-video {
    height: 35vh;
    padding-bottom: 3vh;
  }

  .section-tabs .container-video .video {
    width: 70%;
  }
  .hero-page {
    min-height: 0 !important;
  }
  .clients,
  #contact-us {
    margin-top: 10vh;
  }
  .comment-container p {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .identity-client h3 {
    font-size: 2.8rem;
  }

  .comment-container > div img {
    width: 25px;
  }
}

@media only screen and (min-width: 1500px) {
  .navbarOnTop {
    padding: 25px 4vw 15px 4vw !important;
  }
  .interface-container,
  .landing-container {
    max-width: 1600px !important;
  }
  .landing-container header {
    padding: 48px 0 0 0;
  }
  .landing-container header ul li a {
    font-size: 1.4rem;
  }
  .landing-main > header img {
    width: 12vw;
  }
  .landing-main > header .social #icon:first-child {
    font-size: 2.7rem;
  }
  .landing-main > header .social #icon:nth-child(2) {
    font-size: 2.2rem;
  }
  .landing-main > header .social #icon:nth-child(3) {
    font-size: 2.4rem;
  }
  .landing-main > footer h6 {
    font-size: 2rem !important;
  }
  .landing-main > footer h3 {
    font-size: 4rem !important;
  }
  .play-video button {
    width: 72px;
    height: 72px;
  }
  .interface-container main > h3 {
    font-size: 3rem;
  }
  .interface-card h6 {
    font-size: 1.5rem;
  }
  .interface-card p {
    font-size: 1.2rem;
  }
  .information-container {
    max-width: 1250px;
  }
  .information-container h5 {
    font-size: 2.37rem;
  }
  .information-container h3 {
    font-size: 3.12rem;
  }
  .container-tabs > nav ul li span,
  .container-tabs main div p {
    font-size: 1.2rem;
  }
  .tab-information h6 {
    font-size: 1.5rem;
  }
  .tab-information p {
    font-size: 1.2rem;
  }
  .pasture-numbers div span,
  .piket-numbers h6 {
    font-size: 1.5rem;
  }
  .pasture-numbers div h6 {
    font-size: 2rem;
  }
  #contact-us {
    margin-top: 30vh;
    width: 100%;
    min-width: 1200px;
  }
  #contact-us h1 {
    font-size: 3rem;
  }
  #contact-us p {
    font-size: 1.5rem;
  }
  #contact-us button {
    font-size: 1.3rem;
  }
  .clients-title h6 {
    font-size: 1.9rem;
  }
  .clients-title h4 {
    font-size: 2rem;
  }
  .container-700 {
    max-width: 1000px;
  }
  .use-cases-container header div h6 {
    font-size: 2rem !important;
  }
  .use-cases-container header div h3 {
    font-size: 2.5rem !important;
  }
  .use-cases-card h6 {
    font-size: 1.5rem;
  }
  .use-cases-card p {
    font-size: 1.2rem;
  }
  .use-cases-card button {
    width: 65%;
    font-size: 1rem;
  }
  .footer .social-inline #icon:first-child {
    font-size: 2.5rem !important;
  }
  .footer .social-inline #icon:nth-child(2) {
    font-size: 2rem !important;
  }
  .footer .social-inline #icon:last-child {
    font-size: 2.4rem !important;
  }
}
