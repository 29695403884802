.createFarm__container {
  padding: 30px 0 0 50px;
}

.createFarm__container h1 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 2.25rem;
  color: #4d4d4d;
}

.createFarm__container {
  position: relative !important;
}

.createFarm__container form > .buttononlytitle {
  width: 500px;
}
