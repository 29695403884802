.headerCreateEvaluator {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
}

.footerCreateEvaluator {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.formCreateEvaluator {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px 0
}

.labelCreateEvaluator {
  color: #4D4D4D;
  font-family: "Fira Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.textInputCreteEvaluator {
  border-radius: 9px;
  border: 2px solid #E5E5E5;
  background: #E5E5E5;
  height: 50px;
  width: 100%;
  padding: 0 20px;
}