.select__chevron {
  transition: all ease 1s;
}

.select__drop--error {
  animation-name: bounceTextInput;
  animation-duration: 0.5s;
  animation-delay: 0.25s;
}

@keyframes selectOpen {
  0% {
    background: rgba(0, 0, 0, 0.1);
  }
  95% {
    background: #fff;
  }
  100% {
    background: #fff;
  }
}
@keyframes selectClose {
  0% {
    background: #fff;
  }
  60% {
    background: rgba(0, 0, 0, 0.09);
  }
  100% {
    background: rgba(0, 0, 0, 0.1);
  }
}

@keyframes bounceTextInput {
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(5px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(4px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(2px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
}

.customScrollbar1 {
  display: flex;
 }

/* Este seletor funciona como o LIKE em SQL */
.react-select div[class$="-control"] {
  cursor: pointer;
}
