.custominput__number--container-inline {
  width: 100%;
  border: solid 1px #ddd;
  border-radius: 99999px;
  padding: 5px;
  display: grid !important;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "btnless input btnmore";
}

.custominput__number--disabled {
  background: #FFF;
}

.custominput__number--error {
  border: solid 1px #ff6161;
}

.input__counter-container--inline {
  grid-area: input;
  display: flex;
}

.input__counter-container--inline > input {
  width: 100%;
  height: 100%;
  border-radius: 0;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  padding-right: 4px;
  text-align: right;
  font-family: "Fira Code";
  font-weight: 500;
  font-size: 1.125rem;
  text-transform: uppercase;
  color: #4D4D4D;
}

.input__counter-container--inline>input:disabled {
  background: #FFF;
}

.btn__up--counter-inline,
.btn__down--counter-inline {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.label__only--inline {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Fira Code";
  font-weight: 500;
  font-size: 1.125rem;
  /* text-transform: uppercase; */
  color: #999999;
  cursor: text;
}

.btn__up--counter-inline {
  grid-area: btnmore;
  justify-content: flex-end;
}

.btn__down--counter-inline {
  justify-content: flex-start;
  grid-area: btnless;
}

.btn__up--counter-inline > button,
.btn__down--counter-inline > button {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: #62b8ae;
  cursor: pointer;
}

.btn__up--counter-inline > button > svg {
  font-size: 1.7rem;
  fill: #fff;
}

.btn__down--counter-inline > button > svg {
  font-size: 1.7rem;
  fill: #fff;
}

.btn__up--counter-inline > button:disabled,
.btn__down--counter-inline > button:disabled {
  background: #999999;
  cursor: default;
}

/*[INICIO] Layout in Block  */
.custominput__number--container-block {
  width: 60px;
  height: 52px;
  border: solid 1px #ddd;
  border-radius: 7px;
  overflow: hidden;
  display: grid !important;
  grid-template-areas:
    "input btnmore"
    "input btnless";
}

.input__counter-container--block{
  grid-area: input;
  width: 100%;
  height: 100%;
}

.input__counter-container--block > input {
  width: 100%;
  height: 100%;
  border-radius: 0;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  padding-left: 4px;
  text-align: center;
}

.input__counter-container--block>input:disabled {
  background: #FFF;
}

.btn__up--counter-block,
.btn__down--counter-block {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
}

.btn__up--counter-block {
  grid-area: btnmore;
  justify-content: flex-start;
  border-bottom: solid 1px #fff;
}
.btn__down--counter-block {
  justify-content: flex-start;
  grid-area: btnless;
}

.btn__up--counter-block > button,
.btn__down--counter-block > button {
  width: 100%;
  height: 100%;
  background: #62b8ae;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn__up--counter-block > button > svg,
.btn__down--counter-block > button > svg {
  fill: #fff;
  font-size: 0.9rem;
}

.btn__down--counter-block > button > svg {
  transform: rotateZ(180deg);
}

.btn__up--counter-block > button:disabled,
.btn__down--counter-block > button:disabled {
  background: #999999;
  cursor: not-allowed;
}
