.task-button-container .buttononlytitle--success > h6 {
  color: #4d4d4d !important;
}

.task-button-container .buttononlytitle--light {
  border-color: #999 !important;
}

.task-button-container .buttononlytitle--light > h6 {
  color: #999 !important;
}

.task-question-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
