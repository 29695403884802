.tableHeaderColumnTaskEvaluation {
    width: 100%;
    height: 62px;
    left: 0px;
    background: #4d4d4d;
    border: 2px solid #E5E5E5;
    display: flex;
    align-items: center;
    font-family: "Fira Sans", monospace;
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    color: #ffffff;
    text-transform: capitalize;
    border-radius: 10px 10px 0px 0px;
    border-bottom: 0;
}

.tableHeaderColumnTaskEvaluation>div {
    width: 33%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
}

.tableHeaderColumnTaskEvaluation>div:nth-child(1) {
    width: 200px;
}

.tableHeaderColumnTaskEvaluation>div:nth-child(2) {
    width: 350px;
}

.tableHeaderColumnTaskEvaluation>div:nth-child(3) {
    width: 200px;
}

.tableHeaderColumnTaskEvaluation>div:nth-child(4) {
    width: 200px;
}

.tableHeaderColumnTaskEvaluation>div:nth-child(5) {
    width: 180px;
}

.tableHeaderColumnTaskEvaluation>div:nth-child(6) {
    width: 180px;
}

.tableHeaderColumnTaskEvaluation>div:nth-child(7) {
    width: 100px;
}

.tableViewRows>.rodal {
    position: absolute;
}