.body__formEditOnboarding{
  height: calc(100% - 90px);
}

.city__container {
  display: flex;
}

.city__container__uf {
  width: 100px;
  margin-right: 20px;
}

.city__container__city {
  width: 380px;
}

.season_definition {
  margin-top: 30px;
}

.barSeason{
  position: relative;
  padding: 15px;
  margin-top: 30px;
  z-index: 0;
}

.initial--readOnly {
  background: transparent;
  outline: none;
  border: none;
  margin-top: 10px;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  color: #62b8ae;
  cursor: pointer;
}
