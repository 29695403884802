.hectares__container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 500px;
}

.hectares__value {
  width: 100px;
  margin-right: 20px;
}
.hectares__disponivel {
  width: 380px;
}

.initial--readOnly {
  width: 200px;
  padding-top: 10px;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #62b8ae;
}

.acreageLabel {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.375rem;
  color: #4d4d4d;
  margin: 20px 20px 0 0;
}

.label_batch {
  margin-top: 20px;
  width: 400px;
}
