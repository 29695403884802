.tableVisualizationContainer {
  width: 100%;
  height: 62px;
  left: 0px;
  background: #4d4d4d;
  border: 1px solid #4d4d4d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableVisualizationContainer > div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}

.tableVisualizationContainer > div:nth-child(1) {
  width: 170px;
}

.tableVisualizationContainer > div:nth-child(2) {
  width: 230px;
}

.tableVisualizationContainer > div:nth-child(3) {
  width: 100px;
}

.tableVisualizationContainer > div,
.tableVisualizationContainer__controlls--slider > strong {
  font-family: "Fira Sans", monospace;
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  color: #ffffff;
  text-transform: capitalize;
}

.tableVisualizationContainer__title {
  text-transform: uppercase !important;
}

.tableVisualizationContainer__controlls--slider {
  width: 100%;
  height: 44px;
  background: #62b8ae;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px 10px 0px 0px;
}

.tableVisualizationContainer__controlls--slider > button {
  background: none;
  border: none;
  outline: none;
}

.tableVisualizationContainer__controlls--slider > button > svg {
  font-size: 1.5rem;
}

.containerTable__content--row {
  width: 100%;
  height: 62px;
  display: flex;
}

.containerTable__content--row > div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableVisualizationContainer__categories {
  border: 1px solid #f1f1f1;
  overflow: hidden;
  border-radius: 0px 0px 10px 10px;
}
