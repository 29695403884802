.onboarding__container {
  width: 100%;
  height: 100vh;
  position: relative;
  display: grid;
  grid-template-rows: 124px auto;
  grid-template-columns: 390px auto;
  grid-template-areas:
    "header header"
    "nav content";
}

.onboarding__logo {
  grid-area: header;
  padding: 30px 61px;
  border-bottom: 1px solid #f1f1f1;
}

.onboarding__nav {
  grid-area: nav;
  border-right: 1px solid #f1f1f1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
}

.onboarding__content {
  grid-area: content;
  overflow: hidden;
  /* margin: 50px 51px; */
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  display: grid;
  grid-template-rows: auto 102px;
  grid-template-areas:
    "main"
    "footer";
  /* max-height: 500px; */
}

.onboarding__logo > svg {
  width: 201.96px;
  height: 64px;
}

.onboarding__nav {
  padding-right: 25px;
}

.onboarding__nav--menu {
  overflow: hidden;
  overflow-y: auto;
  padding-right: 20px;
  flex: 1;
}

.onboarding__nav--menu::-webkit-scrollbar {
  width: 9px !important;
}

.onboarding__nav--items {
  padding-right: 1 0px;
  padding-top: 50px;
  padding-bottom: 25px;
}

.onboarding__content > main {
  grid-area: main;
  padding-right: 20px;
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  margin-right: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.onboarding__content > main::-webkit-scrollbar {
  width: 10px !important;
}

.onboarding__content > footer {
  grid-area: footer;
  width: 100%;
  height: 100%;
}

.onboarding__content .global__scrollbar--track {
  height: calc(100% - (40px + 20px));
  top: 40px;
  bottom: 20px;
}