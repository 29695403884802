.modalerror__title--container h3 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  text-align: center;
  color: #4d4d4d;
  margin: 0;
  padding: 0;
  /* margin-bottom: 10px; */
}
