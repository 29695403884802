.plating-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.seed-dropdown-container {
  width: 100%;
}

.seed-dropdown-container .dropdownfilter__menu--container {
  max-height: 168px !important;
}

.seed-dropdown-container .dropdownfilter button > h3,
.seed-dropdown-container .dropdownfilter button > small,
.seed-dropdown-container .dropdownfilter li > button {
  text-transform: none !important;
  font-variant: normal !important;
  font-family: "Fira Sans";
  font-size: 18px;
}

.seed-dropdown-container .dropdownfilter button > small {
  font-size: 18px;
}

.seed-dropdown-container .label__only--inline {
  font-variant: normal;
  text-transform: none;
}
