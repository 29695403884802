.emptyTable_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.emptyTable_container span {
  color: #999;
  text-align: center;
  font-family: "Fira Sans";
  font-size: 15px;
  font-weight: 400;
  font-variant: all-small-caps;
  letter-spacing: 0.3px;
}

.addProduct_button {
  cursor: pointer;
  display: flex;
  height: 50px;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 25px;
  background: #62B8AE;
  width: 350px;
}

.addProduct_button span {
  color: #FFF;
  font-family: "Fira Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  font-variant: all-small-caps;
  letter-spacing: 1.1px;
  margin-bottom: 4px;
}