.rhf__container--input {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: min-content;
  width: 100%;
  border: solid 1px transparent;
  margin-top: 10px;
}

.rhf__header--label {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #4d4d4d;
  width: 100%;
  text-align: left;
}

.rhf__input--content {
  width: 100%;
  height: 51px;
  background: transparent;
  border-radius: 9px;
  overflow: hidden;
  padding: 0 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.rhf__input--contentError {
  width: 100%;
  height: 51px;
  background: transparent;
  border-radius: 9px;
  overflow: hidden;
  padding: 0 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #ff6161 !important;
  animation-name: bounceTextInput;
  animation-duration: 0.5s;
  animation-delay: 0.25s;
}

.rhf__input--content>input,
.rhf__input--contentError>input {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #4d4d4d;
  background: transparent !important;
}

.rhf__prefix--label {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #4d4d4d;
}

.rhf__input--theme-none {
  border: solid 1px transparent;
}

.rhf__input--theme-normal {
  border: solid 1px var(--gray-40);
  background: #fff;
}

.rhf__input--theme-normal:focus-within {
  border: solid 1px #62b8ae;
}

.rhf__input--theme-read-only,
.rhf__input--theme-read-only>input {
  border: solid 1px #f1f1f1;
  background: #f1f1f1;
  cursor: no-drop;
}

.rhf__input--theme-read-only,
.rhf__input--theme-read-only>input,
.rhf__input--theme-read-only>.rhf__prefix--label {
  color: hsl(0, 0%, 60%);
}

.rhf__input--theme-read-only>input {
  border: none;
}

.rhf__prefix-on-right {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.rhf__prefix-on-right>.rhf__prefix--label {
  display: block;
  width: 100%;
}

@keyframes bounceTextInput {
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }

  37% {
    transform: translateX(5px);
    timing-function: ease-out;
  }

  55% {
    transform: translateX(0px);
    timing-function: ease-in;
  }

  73% {
    transform: translateX(4px);
    timing-function: ease-out;
  }

  82% {
    transform: translateX(0px);
    timing-function: ease-in;
  }

  91% {
    transform: translateX(2px);
    timing-function: ease-out;
  }

  96% {
    transform: translateX(0px);
    timing-function: ease-in;
  }

  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
}