.headerContentModal__container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.headerContentModal__container > button {
  width: 48px;
  height: 48px;
  border: 2px solid #f1f1f1;
  background: transparent;
  box-sizing: border-box;
  border-radius: 32px;
  padding: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.headerContentModal__container > button > svg {
  width: 100%;
  height: 100%;
}
