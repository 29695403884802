.tableItemEdit__row {
  height: 94px;
}

/* Isso bloqueia as duas primeiras células em caso de rolagem */
.tableItemEdit__row td:nth-child(1),
.tableItemEdit__row td:nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  border-right: 1px solid #f1f1f1;
  z-index: 800;
}

.tableItemEdit__row td {
  border-right: 1px solid #f1f1f1;
  background: #ffffff;
  position: relative;
}

.tableItemEdit__row:nth-child(2n),
.tableItemEdit__row:nth-child(2n) td {
  background-color: #f1f1f1 !important;
  border-right: solid 1px #ffffff;
}

/* Isso faz com que a primeira célula seja bloqueada em caso de rolagem */
.tableItemEdit__row td:nth-child(1) {
  left: 0;
  max-width: 50px;
}

/* Isso faz com que a segunda célula seja bloqueada em caso de rolagem */
.tableItemEdit__row td:nth-child(2) {
  left: 105px;
}

.tableItemEdit__row td:nth-child(1) > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableItemEdit__row td div h4 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #4d4d4d;
  text-align: center;
  width: 100%;
}

.textinput__weight {
  position: relative;
  width: 80px;
  height: 52px;
  margin: 0 auto;
  background: #fff;
  padding: 8px;
  border-radius: 7px;
  border: 1px solid #f1f1f1;
}

.tableItemEdit__row td div strong {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  color: #4d4d4d;
}

.tableItemEdit__label {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: text;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
}

.tableItemEdit__label:focus-within {
  border: 1px solid #62b8ae;
}

.tableItemEdit__label span {
  display: block;
  width: 50px;
  text-align: left;
  color: #999999;
  text-transform: lowercase;
}

.tableItemEdit__label > .textInput__container {
  width: 100%;
}

.tableItemEdit__label > .textInput__container input {
  border: none;
  padding: 0;
  background: transparent;
  text-align: right;
  padding-right: 10px;
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 24px;
  color: #000;
}

.labelNotValid {
  border: solid 1px #ff6161 !important;
  animation-name: bounceTextInput;
  animation-duration: 0.5s;
  animation-delay: 0.25s;
}

@keyframes bounceTextInput {
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(5px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(4px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(2px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
}
