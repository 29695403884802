.tableRowTaskEvaluation {
  display: flex;
  flex-direction: row;
  height: 62px;
  width: 100%;
  background: #ffffff;
}

.tableRowTaskEvaluation:nth-child(2n) {
  background: #f1f1f1;
}

.tableRowTaskEvaluation:nth-child(2n) div:not(:last-child) {
  background: #f1f1f1;
  border-right: 2px solid #ffffff;
}

.tableRowTaskEvaluation>div {
  width: 33%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Fira Sans", monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  padding: 20px;
  cursor: default;
  border-right: 2px solid #f1f1f1;
}

.tableRowTaskEvaluation>div:nth-child(1) {
  width: 200px;
}

.tableRowTaskEvaluation>div:nth-child(2) {
  width: 350px;
}

.tableRowTaskEvaluation>div:nth-child(3) {
  width: 200px;
}

.tableRowTaskEvaluation>div:nth-child(4) {
  width: 200px;
}

.tableRowTaskEvaluation>div:nth-child(5) {
  width: 180px;
}

.tableRowTaskEvaluation>div:nth-child(6) {
  width: 180px;
}

.tableRowTaskEvaluation>div:nth-child(7) {
  width: 100px;
}

.tableRowTaskEvaluation:nth-last-child() {
  border-radius: 0px 0px 10px 10px;
}