.tabs-button-collapse {
  width: 100%;
  border: 0;
  background: transparent;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px var(--white-10);
  padding: 0 5px;
  margin-top: 8vh;
  transition: ease 300ms;
}

.tabs-button-collapse:active{
    transform: scale(.9);
}

.tabs-button-collapse span {
  font-size: 1rem;
  font-family: ampleBold;
  font-weight: bold;
  letter-spacing: 1px;
  color: var(--green-80);
}
.tabs-button-collapse svg {
  font-size: 1.5rem;
  color: var(--white-10);
  transition: ease 800ms;
}
