.buttonwithchildren__container {
  width: 100%;
  height: 52px;
  background: #4d4d4d;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  overflow: hidden;
  cursor: pointer;
}

.buttonwithchildren__container > .buttonWithChildren--content-inline {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.buttonwithchildren__container > .buttonWithChildren--content-inline > svg {
  margin-right: 10px;
}

.buttonwithchildren__container > .buttonWithChildren--content-inline > h6 {
  margin: 0;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 26px;
  letter-spacing: 0.05em;
  font-variant: small-caps;
  text-transform: uppercase;
  color: #ffffff;
}

/* INFO */
.buttonwithchildren__info {
  background: #62b8ae;
  border: 2px solid #62b8ae;
}

/* Dark */
.buttonwithchildren__dark {
  background: #4d4d4d;
  border: 2px solid #4d4d4d;
}

/* LIGHT */
.buttonwithchildren__light {
  background: #fff;
  border: 2px solid #f1f1f1;
}

/* DISABLED */
.buttonwithchildren__disabled {
  background: #e5e5e5;
  color: #999999;
  cursor: not-allowed;
}
