.buttonPressed {
  background: #57CE71;
  border-radius: 25px;
}

.buttonPressed span {
  color: #FFF;
  font-family: "Fira Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  font-variant: all-small-caps;
  letter-spacing: 1.1px;
}

.buttonNotPressed {
  border: 2px solid #999;
  background: #FFF;
  border-radius: 25px;
}

.buttonNotPressed span {
  color: #4D4D4D;
  font-family: "Fira Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  font-variant: all-small-caps;
  letter-spacing: 1.1px;
}

.containerButtonsVertical {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.containerButtonsHorizontal {
  display: flex;
  gap: 10px;
}

.containerButtonsHorizontal .buttonPressed,
.containerButtonsHorizontal .buttonNotPressed {
  display: flex;
  justify-content: center
}