.bodyCard {
  min-width: 210px;
  min-height: 140px;
  background-image: url("../../assets/png/gradientMap.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #324440;
  border: 2px solid #f1f1f1;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.bodyCard__nameCard {
  font-family: "Fira Sans" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 26px !important;
  letter-spacing: 0.03em !important;
  font-variant: small-caps !important;
  color: #ffffff !important;
  position: absolute !important;
  top: 90px !important;
  min-width: 122px !important;
  max-width: 70% !important;
  text-transform: uppercase !important;
}

.bodyCard__profile {
  font-family: "Fira Sans" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: normal !important;
  letter-spacing: 0.36px !important;
  font-variant: all-small-caps !important;
  color: #ffffff !important;
  position: absolute !important;
  text-transform: uppercase !important;
  bottom: 0px !important;
}

.bodyCard_infos {
  display: flex;
  gap: 8px;
}

.bodyCard_infos--card {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.bodyCard_infos--card>h3 {
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0 !important;
}

.bodyCard_infos--card>svg {
  width: 24px;
  height: 22px;
  margin-right: 8px;
}

.bodyCard_infos--card>svg>path {
  fill: #57ce71;
}

.edit__button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: #62b8ae;
  border: 2px solid #62b8ae;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.edit__button>svg {
  width: 18px;
  height: 18px;
}

.edit__button>svg>path {
  fill: #ffffff;
}

.edit__container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.bodyCard__initials {
  background: #4d4d4d;
  display: flex;
  align-items: center;
  border: 1px solid #999999;
  border-radius: 10px;
  opacity: 0.9;
  height: 52px;
  padding: 0 11px;
  width: fit-content;
  text-align: end;
}

.bodyCard__initials>svg {
  width: 27px;
  resize: both;
  margin-right: 12px;
}

.bodyCard__initials>h3 {
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fff;
  width: auto;
  margin: 0 !important;
}