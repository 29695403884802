.footer-controlls__container {
  display: flex;
  justify-content: flex-start;
  padding: 30px 0;
  padding-left: 51px;
  border-top: solid 1px #f1f1f1;
}

.footer-controlls__container > button {
  margin-right: 12px;
}
.footer-controlls__container #buttonprevstep,
.footer-controlls__container #buttonnextstep {
  width: 169px;
}
