.logoPastejo {
  width: 200px;
  padding: 30px 0 87px 0;
}

.formItens {
  z-index: 99;
  position: absolute;
  background: linear-gradient(90deg, #324440 0%, rgba(50, 68, 64, 0.00) 100%) !important;
  width: 50%;
  height: 100%;
}

.formHeader {
  padding-left: 100px;
  background: linear-gradient(180deg, #324440 0%, rgba(50, 68, 64, 0.00) 100%);
  height: 20%;
  width: 100vw;
}

.formContainer {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-left: 100px;
}

.formFooter {
  height: 20%;
  width: 100%;
}

.buttonsFooter {
  width: 100%;
  position: fixed;
  right: 0px;
  bottom: 0px;
  padding: 0 100px 30px 0;
  display: flex;
  justify-content: flex-end
}

.buttonsFooter .cancel button {
  width: 180px;
}

.buttonsFooter button {
  padding: 10px 40px;
}

.formTitle {
  color: #FFF;
  font-family: "Fira Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.formSubTitle {
  color: #FFF;
  font-family: "Fira Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 500px;
}

.formCreateMicroArea {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.iconBatch {
  width: 52px;
  height: 52px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -25px;
  margin-top: -26px;
  background-image: url("../../../../assets/svg/icons/animais.svg");
  background-repeat: no-repeat;
  background-position: 50%;
}

.labelForm {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.375rem;
  color: #fff;
}

.backgroundMap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
}

.containerForm {
  display: flex;
  width: 100%;
}

.currentMicroAreaContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.backMicroArea {
  height: 100%;
  margin-left: 5vw;
  position: fixed;
  top: 50%;
  cursor: pointer;
  user-select: none;
}

.backMicroArea svg {
  scale: -1;
}

.nextMicroArea {
  position: fixed;
  right: 5vw;
  top: 50%;
  height: 100%;
  cursor: pointer;
  user-select: none;
}

.errorFooter {
  position: absolute;
  bottom: 0;
  padding: 0 0 30px 100px;
}