.birthGoal__title--container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 500px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.birthGoal__title--container h3 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5625rem;
  letter-spacing: 0.02em;
  color: #4d4d4d;
}

.birthGoal__title--container h4 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2625rem;
  letter-spacing: 0.02em;
  color: #4d4d4d;
  width: 100%;
  margin: 0;
  padding: 0;
}

.birthGoal__title--container button {
  background-color: transparent;
  border: none;
  outline: none;
}

.modalEdit__message--error {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 22px;
  color: #ff6161;
  display: block;
  margin-top: 8px;
}
