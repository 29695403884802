.createuser__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.createuser__header > h3 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #4d4d4d;
  margin: 0;
}

.createuser__header > button {
  background: transparent;
  transition: all ease-in-out 400ms;
  /* transform: scaleX(3); */
}

.createuser__header > button:active {
  transform: scale(0.4);
}

.createuser__content:not(:first-child) {
  margin-bottom: 15px;
}

.createuser__content .container__input label {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 22px;
  color: #4d4d4d;
  margin: 10px 0;
}

.createuser__delimiter {
  width: 100%;
  height: 1px;
  display: block;
  background: #f1f1f1;
  border-radius: 1px;
  margin: 20px 0;
}

.createuser__container--removeprofile {
  margin-bottom: 10px;
}
