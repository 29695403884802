.modalerror__text--container h6 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #999999;
  margin: 0;
  padding: 0;
}
