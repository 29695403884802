.columnPastureManagement {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.body__formPastureManagement {
    overflow-y: scroll;
    height: calc(100vh - 330px);
    position: relative;
    display: flex;
    gap: 60px;
    margin-bottom: 30px;
}

.frequencyDay__formPastureManagement {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #e5e5e5 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #f5f5f5;
    user-select: none;
    transition: background-color 0.3s, color 0.3s;
}

.frequencyDay__formPastureManagement:hover {
    background-color: #62b8aeaa;
    color: #f5f5f5;
}

.frequencyDay__formPastureManagement--selected {
    color: #f5f5f5;
    background-color: #62b8ae;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #e5e5e5 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.daySelect__formPastureManagement .rdp,
.daySelect__formPastureManagement .rdp .rdp-caption_label {
    margin: 0;
    padding: 0;
}