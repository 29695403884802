.search__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 20px;
  gap: 10px;
  width: auto;
  height: 52px;
  border: 2px solid #f1f1f1;
  border-radius: 10px;
  justify-content: space-between;
}

.search__input {
  width: 100%;
  height: 30px;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  border: transparent;
  outline: none;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.search__input:focus {
  border: transparent;
}

.search__icon {
  fill: #62b8ae;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
