.session-template__container {
  height: 100vh;
  width: 100%;
  overflow: auto;
  background: url("../../../assets/png/gradientMap.png") no-repeat #324440;
  background-size: cover;
  background-position: center;
}

.session-template__container header {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: 35px 60px 25px 60px;
  position: sticky;
  top: 0;
  z-index: 800;
  transition: all ease 2s;
}

.session-template__container > .onTop {
  animation: backgroundOnTop 100ms forwards;
}

@keyframes backgroundOnTop {
  from {
    background: transparent;
    border-bottom: none;
  }
  to {
    background: #324440;
    border-bottom: solid 1px #087d6f;
  }
}

.session-template__header--logo svg {
  height: 50px;
}

.session-template__header--links ul {
  display: inline-flex;
}

.session-template__header--links ul li {
  list-style: none;
}

.session-template__header--links ul li:first-child {
  margin-right: 15px;
}

.session-template__header--links ul li a {
  font-family: "Fira Sans", monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  text-align: right;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}

.session-template__header--links ul li .active {
  color: #57ce71 !important;
  position: relative;
  text-decoration-line: underline !important;
  text-underline-offset: 8px;
}

.session-template__container main {
  height: auto;
  margin-bottom: 30px;
}
