.sidebar_menuoption--button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: transparent;
  border: none;
  text-decoration: none;
  cursor: pointer;
  margin-top: 10px;
}

.sidebar_menuoption--button:last-child {
  margin-bottom: 20px;
}

.sidebar_menuoption--button>div {
  width: 16px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 0.3rem;
}

.sidebar_menuoption--selected span {
  transition: all 170ms ease;
  font-weight: 600 !important;
  text-transform: uppercase;
}

#sidebar_menuoption--text {
  font-family: Fira Sans;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: uppercase;
  color: #4D4D4D;
}

.sidebar_subMenuoptionIcon {
  transform: rotate(0deg);
  transition: transform 0ms ease-in-out, top 0ms ease-in-out, left 0ms ease-in-out;
  position: initial;
  left: 0px;
  top: 0px;
}

.sidebar_subMenuoptionIcon--selected {
  transform: rotate(90deg);
  transition: transform 200ms ease-in-out 200ms,
    top 200ms ease-in-out 200ms,
    left 200ms ease-in-out 200ms;
  position: relative;
  left: -3px;
  top: 3px;
}

.sidebar_subMenuoption--collapsible {
  max-height: 0;
  transition: max-height 400ms ease-in-out;
  overflow: hidden;
  margin-left: 20px;
}

.sidebar_subMenuoption--collapsible--selected {
  max-height: 1000px;
  transition: max-height 400ms ease-in-out 200ms;
  overflow: hidden;
  margin-left: 20px;
}

.sidebar_menuoption--subitems {
  transition: opacity 200ms ease-in-out 400ms;
  opacity: 0;
}

.sidebar_menuoption--subitems--selected {
  opacity: 1;
}