.leaflet__container--constrolls {
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 400;
  display: flex;
  flex-direction: column;
}

.leaflet__container--constrolls button:not(:last-child) {
  margin-bottom: 10px;
}

.leaflet__container--constrolls > .custom__button--location,
.leaflet__container--constrolls > .custom__button--zoomout,
.leaflet__container--constrolls > .custom__button--zoomin {
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 20px;
}

.leaflet__container--constrolls > .custom__button--location > svg > circle,
.leaflet__container--constrolls > .custom__button--zoomin > svg > path,
.leaflet__container--constrolls > .custom__button--zoomout > svg > path {
  stroke: #4d4d4d !important;
}

.leaflet__container--constrolls > .custom__button--location > svg > rect {
  fill: #4d4d4d;
}

.leaflet-tooltip-pane {
  z-index: 999999 !important;
}