.pdfContainer {
  /* display: none; */
  width: 900px;
  position: absolute;
  left: 50%;
  border-right: 1px solid black;
  border-left: 1px solid black;
}

.pdfPageMapContainer {
  width: 100%;
  position: relative;
}

.pdfPage {
  height: 1440px;
  padding: 100px 115px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid black;
  background: #fff;
}

.pdfPageMap {
  z-index: 99;
  position: absolute;
  background: linear-gradient(0deg,
      #324440 0%,
      rgba(50, 68, 64, 0) 100%) !important;
  width: 100%;
  height: 100%;
  padding: 100px 140px;
}

.pdfSumario {
  background: #fff;
  height: 1680px;
  padding: 100px 140px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
    -moz-print-color-adjust: exact;
    -ms-print-color-adjust: exact;
    print-color-adjust: exact;
    box-shadow: inset 0 0 0 1000px #FFF;
  }

  .pdfSumario {
    padding: 100px 140px;
    height: 1446px;
    width: 1025px;
    border-bottom: 0px solid black;
    zoom: 150% !important;
  }

  .pdfPage {
    padding: 100px 180px;
    height: 1446px;
    width: 1025px;
    border-bottom: 0px solid black;
    zoom: 150% !important;
  }

  .pdfPageMapContainer {
    padding-top: 5px;
  }

  .pdfContainer {
    display: block;
    position: absolute;
    left: 0;
    border-right: 0 solid black;
    border-left: 0 solid black;
  }

  .pdfPageMap {
    padding: 100px 140px;
    height: 1446px;
    width: 1025px;
  }
}

.pdfContent>div>h1 {
  font-family: "Fira Sans";
  font-size: 24px;
  line-height: 95%;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  margin: 0;
  margin-bottom: 18px;
}

.pdfPageMap .areaContainer {
  padding: 10px;
  background: #365951;
  width: 100%;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.pdfPageMap .areaContainer .whiteColor * {
  fill: #fff;
}

.pdfPageMap .areaContainer .greenColor * {
  fill: #62b8ae;
}

.pdfContent>div>.areaContainer>h1 {
  font-family: "Fira Code";
  font-size: 20px;
  line-height: 90%;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-align: left;
  margin: 0;
  color: #62b8ae;
}

.pdfPageMap .detailareaContainer {
  padding: 14px;
  background: #ffffff;
  width: 100%;
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.pdfPageMap .detailareaContainer h1 {
  color: #4d4d4d;
  font-family: "Fira Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: 0em;
  text-align: left;
}

.pdfPageMap .detailareaContainer span {
  color: #999999;
  font-family: "Fira Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0em;
  text-align: left;
}

.pdfContent {
  height: 95%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pdfHeaderContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.pdfHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.pdfHeader h1 {
  font-family: "Fira Sans";
  font-size: 55px;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  width: 350px;
}

.pdfHeader h2 {
  color: #fff;
  font-family: "Fira Sans";
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
}

.pdfHeader .pdfLogoPastejo {
  width: 300px;
}

.pdfFooter {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 50px;
}

.pdfFooter .pdfLogoPastejo {
  width: 150px;
  height: 40px;
}

.pdfFooter span {
  color: #000;
  font-family: "Fira Code";
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
}

.pdfTopicContainer {
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 10px;
}

.pdfTopicId {
  width: 45px;
  font-family: "Fira Sans";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #4d4d4d;
  margin: 5px 0;
}

.pdfTopic {
  font-family: "Fira Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #4d4d4d;
  margin: 2px 0;
}

.pdfMicroArea {
  font-family: "Fira Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #62b8ae;
  text-transform: uppercase;
}

.pdfContainerResult {
  display: flex;
  gap: 20px;
}

.pdfContainerResultMap {
  height: unset;
  width: 30%;
}

.pdfContainerPhoto {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.pdfPhoto {
  width: 49%;
  height: 250px;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Fira Sans";
  font-size: 12px;
  color: #4d4d4d;
}

/* Table */

.pdfTableColumn {
  left: 0px;
  background: #4d4d4d;
  border: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  font-family: "Fira Sans", monospace;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  text-transform: capitalize;
  border-bottom: 0;
  width: 100%;
}

.pdfTableColumn>div {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1px 10px;
}

.pdfTableRow {
  display: flex;
  flex-direction: row;
  line-height: 150%;
  width: 100%;
}

.pdfTableRow>div {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Fira Sans", monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding: 1px 10px;
  cursor: default;
  border-right: 1px solid #f1f1f1;
}

.pdfTableViewRows {
  border: 1px solid #f1f1f1;
  border-right: 0;
  overflow: hidden;
  width: 100%;
}

.pdfTableViewRows>span {
  font-family: "Fira Sans", monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding: 1px 10px;
}

.pdfTableBorder {
  border-radius: 10px;
  border: 1px solid #f1f1f1;
  overflow: hidden;
  margin-bottom: 20px;
}

.pdfSumario>h2 {
  font-family: "Fira Sans";
  font-size: 24px;
  line-height: 95%;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #62b8ae;
  margin: 0;
  margin-bottom: 18px;
  text-transform: uppercase;
}

.pdfPageContent {
  display: flex;
  justify-content: space-between;
  padding: 2px 0;
}

.pdfPageIndice {
  width: auto;
  font-family: "Fira Sans";
  height: 16px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: right;
  color: #4d4d4d;
  background: #fff;
  text-transform: uppercase;
  text-wrap: nowrap;
  padding-right: 10px;
}

.pdfPageLine {
  overflow: hidden;
}

.pdfPageNumber {
  height: 16px;
  font-size: 14px;
  font-family: "Fira Sans";
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #4d4d4d;
  padding-left: 10px;
  overflow: visible;
}

.bg_white {
  background: #fff;
}

.bg_gray {
  background: #f1f1f1;
}

.noData {
  display: flex;
  width: 285px;
  height: 285px;
  justify-content: center;
  align-items: center;
}

.noData span {
  font-family: "Fira Sans";
  color: #4d4d4d;
}