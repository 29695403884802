.gm-style-cc,
.gm-style-mtc {
  display: none;
}

.gmnoprint {
  right: 40px !important;
  bottom: 85px !important;
}

.gmnoprint > div {
  background: transparent !important;
}

.gm-control-active {
  border-radius: 50% !important;
  background: #fff !important;
  margin-bottom: 5px !important;
}

.cattleMapTypeView {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #fff;
  display: flex;
  padding: 0 35px;
}

.cattleMapTypeView > div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.cattleMapTypeView > div > label {
  margin-left: 8px;
}