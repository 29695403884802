.containerGraph {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-bottom: 20px;
}

.containerGraph > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleGraph {
  font-family: "Fira Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0em;
  text-align: center;
  color: #4d4d4d;
  margin-bottom: 10px;
}

.contentGraph {
  border-radius: 20px;
  border: 1px solid #dfe1e2;
  /* padding: 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 280px !important;
  height: 280px !important;
}

.contentGraph.fullWidth {
  width: 675px !important;
  height: 280px !important;
  padding: 20px;
}

.contentGraphBorder {
  border-radius: 20px;
  border: 1px solid #dfe1e2;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.containerGraphFull {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
  width: 675px !important;
}

.chart-legend__container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 0;
  column-gap: 10px;
  height: auto;
}

.chart-legend__item {
  display: flex;
  gap: 5px;
  align-items: center;
}

.chart-legend__text {
  font-family: "Fira Sans";
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0em;
  width: auto;
  color: #7a7a7a;
}

.chart-legend__icon {
  width: 8px;
  height: 8px;
}
