.inner-task-card {
  width: 338px;
  height: 119px;
  padding: 20px;
  border-radius: 20px;
  background-color: #324440;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.outter-task-card {
  width: 348px;
  height: 119px;
  border-radius: 20px;
  background-color: #57ce71;
}

.inner-task-card > p {
  font-weight: 600;
  font-size: 24px;
  line-height: 28.8px;
  color: #ffffff;
}

.task-card-icon > svg  {
  height: 50px;
}

.task-card-icon > svg > path {
  fill: #57ce71;
}
