.legendRounded__container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.legendRounded__container > span {
  display: block;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.legendRounded__container > strong {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  letter-spacing: 0.03em;
  font-variant: small-caps;
  color: #ffffff;
  margin: 0;
  padding: 0;
  margin-left: 5px;
}
