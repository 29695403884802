/* 
    * As estilizações deste componente como:
    * largura(width)
    * atura(height) 
    * devem ser feitas em cascata no componente pai.
    * ex: .classepai/idpai > .drawfarm__label--container{} 
*/

.drawfarm__label--container {
  background: #4d4d4d;
  border-radius: 10px;
  position: absolute;
  z-index: 9;
  top: 40px;
  left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  min-width: 100px;
}

.drawfarm__label--container > svg {
  width: 36px;
  resize: both;
  margin-right: 12px;
}

.drawfarm__label--container > h3 {
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0 !important;
  padding: 0;
}
