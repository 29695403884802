.navbar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 36px 0 0 0;
  z-index: 2;
}

.navbarOnTop {
  /* mantem a nav bar no top após rolar 
      80% do tamanho da tela do usuário */
  position: fixed;
  display: flex;
  align-items: center;
  min-width: 100%;
  top:0;
  transition: ease 300ms;
  z-index: 999 !important;
  left: 0;
  padding: 22px 28px 14px 28px !important;
  background: var(--dark-90);
  margin: 0 !important;
}

.navbar > svg {
  display: none;
}

.navbarOnTop .logo img {
  width: 7vw;
}

.logo img {
  width: 12vw;
}

.responsiveMenu {
  display: none;
}

.navbar ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* gap: 16px; */
}

.navbar ul li {
  list-style: none;
  margin-left: 16px;
}

.navbar ul li a {
  text-decoration: none;
  color: var(--white-10);
  font-size: 1rem;
  font-family: ampleLight, sans-serif;
  transition: 0.5s font-variation-settings ease-in;
}

.navbar ul li a:hover {
  color: var(--green-70);
  font-family: ampleBold, sans-serif;
}

.navbar ul li:nth-child(5) {
  border: solid 1px var(--white-10);
  padding: 12px 16px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease 600ms;
  cursor: pointer;
}

.navbar ul li:nth-child(5):hover {
  background: var(--white-10);
}

.navbar ul li:nth-child(5):hover a {
  color: #000;
  font-family: ampleBold, sans-serif;
}

.navbar ul li:nth-child(5) a {
  color: var(--green-70);
  font-family: ampleBold;
}

.navbar nav .navbar-socials {
  display: none;
}

@media only screen and (max-width: 1050px) {
  /*quando chegamos no mobile, a 
  navbar desktop precisa sumir e a mobile aparece*/
  .navbar nav {
    display: none;
  }

  .navbar {
    padding: 0;
    margin: 8vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 99;
  }

  .navbar > svg {
    display: block;
    font-size: 2rem;
    color: var(--white-10);
  }

  .navbar .logo img {
    width: 20vw;
    resize: both;
  }

  .responsiveMenu {
    display: block;
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.78) 50%,
      rgba(0, 0, 0, 0) 100%
    );
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: ease 400ms;
  }

  .navbar .responsiveMenu nav {
    height: 100%;
    background: var(--white-10);
    display: flex;
    align-self: flex-end;
    flex-direction: column;
    justify-content: space-between;
    padding: 10vw;
  }

  .navbar .responsiveMenu nav svg {
    display: block;
    font-size: 2rem;
    align-self: flex-end;
  }

  #icon:active {
    transform: scale(0.9);
  }

  .navbar .responsiveMenu nav ul {
    flex-direction: column;
    /* gap: 40px; */
    padding: 0 12px;
  }

  .navbar .responsiveMenu nav ul li{
    margin-bottom: 40px;
  }

  .navbar .responsiveMenu nav ul li:nth-child(6) {
    border: solid 1px var(--green-80);
    width: 100%;
    border-radius: 6px;
  }

  .navbar .responsiveMenu nav ul li a {
    color: black;
    font-size: 1.2rem;
  }

  .navbar .responsiveMenu nav .navbar-socials {
    display: block;
    margin-top: 25px;
    font-size: 1.2rem;
    display: flex;
    justify-content: space-around;
    padding: 12px 0;
    color: var(--green-80);
  }

  .navbar .responsiveMenu nav .navbar-socials svg:nth-child(2) {
    font-size: 1.8rem;
  }
  .navbar .responsiveMenu nav .navbar-socials svg:nth-child(3) {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 450px) {
  .navbar .logo img {
    width: 35vw;
    resize: both;
  }

  .navbar .responsiveMenu nav {
    padding: 18px;
  }
}
