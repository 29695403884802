.cattleMapIF__container--cattleInfo {
  display: flex;
  flex-direction: row;
  padding: 4px;
  margin-bottom: 4px;
  background: #f7f9fa;
  align-items: center;
  border-radius: 8px;
  width: 100%;
}

.container__cattleInfo--icon {
  margin-right: 4px;
}



.cattleMapIF__container--iconsInfo {
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cattleMapIF__container--icons {
  border-radius: 8px;
  background: #f7f9fa;
}

.cattleMapIF__warning--levelOne {
  border: solid 2px #ff9501;
}

.cattleMapIF__warning--levelTwo {
  background: #ff9501;
}

.cattleMapIF__warning--notification {
  border: solid 2px #ff9501;
  position: relative;
}

.cattleMapIF__warning--notification::after {
  content: "!";
  width: 12px;
  height: 12px;
  background: #ff9501;
  position: absolute;
  right: -6px;
  top: -6px;
  border-radius: 50%;
  color: #f7f9fa;
  font-size: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
