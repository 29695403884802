.tab{
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 0;
}
.tab > p {
  margin-top: 25px;
  font-family: ampleLight, sans-serif;
  color: var(--white-10) !important;
  font-weight: 100;
}

.tab-content {
  display: flex;
  margin-top: 50px;
}

.tab-text-informations h5 {
  font-size: 1.1rem;
  font-weight: 800;
  font-family: ampleBold, sans-serif;
  color: var(--blue-10);
}

.tab-information {
  margin-top: 25px;
}

.tab-information h6 {
  font-size: 0.9rem;
  margin-bottom: 4px;
  font-weight: 800;
  font-family: ampleBold, sans-serif;
  color: var(--blue-10);
}

.tab-information p {
  font-size: 0.8rem;
  margin-bottom: 4px;
  font-weight: 400;
  font-family: ampleLight, sans-serif;
  color: var(--white-10);
}

.tab-content .tab-text-informations {
  flex: 3;
}

.tab-content .tab-numbers-informations {
  flex: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.tab-numbers-informations h3 {
  text-align: right;
  font-size: 2rem;
  font-family: ampleLight;
  color: var(--blue-10);
}

.tab-numbers-informations > main {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}

.piket-numbers {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.piket-numbers h1 {
  font-size: 4rem;
  font-family: ampleLight;
  color: var(--blue-10);
}

.piket-numbers h6 {
  font-size: 1.5rem;
  text-align: right;
  font-weight: 100;
  font-family: ampleLight;
  color: var(--white-10);
}

.pasture-numbers {
  display: inline-flex;
  /* gap: 25px; */
  margin-top: 25px;
}

.pasture-numbers > div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 25px;
}

.pasture-numbers > div h6 {
  font-size: 2rem;
  font-family: ampleBold;
  font-weight: 800;
  color: var(--blue-10);
}

.pasture-numbers > div span {
  font-size: 1rem;
  font-weight: 200;
  font-family: ampleLight;
  color: var(--white-10);
}

.tab-fade-in {
  animation-name: tab-fade-in;
  animation-duration: 800ms;
  animation-fill-mode: forwards;
  animation-delay: 300ms;
}

@keyframes tab-fade-in {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    height: auto;
  }
}

@keyframes tab-fade-out {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    height: 0;
  }
}

@media only screen and (max-width: 450px) {
  .tab > p{
    letter-spacing: 1px;
    font-family: ampleLight;
    font-weight: 200;
    font-size: 1.1rem;
  }

  .tab-content{
    display: flex;
    flex-direction: column;
  }

  .tab-text-informations h5{
    font-size: 1.3rem;
  }

  .tab-information h6{
    color: var(--gray-80);
    font-size: 1.1rem;
  }

  .tab-information p{
    color: var(--gray-80);
    font-size: 1rem;
    margin-top: 2vh;
    letter-spacing: 1px;
    line-height: 1.5rem;
  }

  .tab-numbers-informations h3{
    margin: 4vh 0;
    text-align: left;
  }

  .piket-numbers{
    align-items: flex-start;
    width: 100%;
  }

  .piket-numbers h6{
    text-align: left;
  }

  .pasture-numbers{
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .pasture-numbers div{
    display: flex;
    align-items: flex-start;
  }

  .pasture-numbers div span{
    font-size: 1.5rem;
  }
}
