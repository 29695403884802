.multiple-select > button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #ffffff;
  border-radius: 9px;
  width: 100%;
  padding: 0 20px;
  min-height: 50px;
  transition: all ease 300ms;
}

.multiple-select > .menuIsOpen {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.multiple-select > button > small {
  display: block;
  font-family: "Fira Sans";
  font-variant: normal;
  text-transform: none;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #999999;
  margin-right: 10px;
  white-space: nowrap;
}

.multiple-select > button > h3 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  font-variant: normal;
  text-transform: none;
  color: #4d4d4d;
  margin: 0;
  width: 100%;
  text-align: left;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multiple-select > button > .icon {
  margin-left: 4px;
}

.multiple-select .dropdownfilter__menu--container {
  background: #ffffff;
  cursor: pointer;
  padding: 10px 0;
  border-top: solid 2px #f1f1f1;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  margin-bottom: 0;
}

.multiple-select .dropdownfilter__menu--container > li {
  list-style: none;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.multiple-select .dropdownfilter__menu--container > .isSeleted > button {
  color: #4d4d4d;
  font-weight: 600;
}

.multiple-select .dropdownfilter__menu--container > li > button {
  width: 100%;
  padding: 0 10px;
  text-align: left;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  font-variant: normal;
  text-transform: none;
  color: #999999;
  background: transparent;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.multiple-select .dropdownfilter__menu--container > li:not(:last-child) {
  padding-bottom: 10px;
}

.multiple-select .dropdownfilter__menu--content {
  max-height: 300px;
  background: #fff;
  overflow: hidden;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
}

.multiple-select .dropdownfilter__menu--container {
  overflow-y: auto;
  max-height: 168px;
}

.checkbox-button {
  width: 18px;
  height: 18px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}

.isSeleted .checkbox-button {
  background: url(../../assets/svg/icons/check.svg) no-repeat center;
}
