.buttononlytitle {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
}

.buttononlytitle > h6 {
  margin: 0;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 26px;
  letter-spacing: 0.05em;
  font-variant: small-caps;
  text-transform: uppercase;
}

/* Variações de estilo */

/* DISABLED */
.buttononlytitle:disabled {
  background: #e5e5e5;
  border: 2px solid #e5e5e5;
  cursor: not-allowed;
}

.buttononlytitle:disabled > h6 {
  color: #999999;
}


/* INFO */
.buttononlytitle--info {
  background: #62b8ae;
  border: 2px solid #62b8ae;
}
.buttononlytitle--info > h6 {
  color: #ffffff;
}

/* LIGHT */
.buttononlytitle--light {
  background: #fff;
  border: 2px solid #f1f1f1;
}
.buttononlytitle--light > h6 {
  color: #4d4d4d;
}

/* Urgent */
.buttononlytitle--urgent {
  background: #FF6161;
  border: 2px solid #FF6161;
}
.buttononlytitle--urgent > h6 {
  color: #fff;
}

/* Success */
.buttononlytitle--success {
  background: #57ce71;
  border: 2px solid #57ce71;
}
.buttononlytitle--success > h6 {
  color: #fff;
}

/* Dark */
.buttononlytitle--dark {
  background: #4d4d4d;
  border: 2px solid #4d4d4d;
}
.buttononlytitle--dark > h6 {
  color: #fff;
}
