.textInput__container {
  height: auto;
}

.textInput__container input {
  height: 51px;
  width: 100%;
  outline: none;
  border: solid 1px var(--gray-40);
  padding: 0 12px;
  border-radius: 9px;
  font-size: 18px;
  padding-left: 25px;
}

.textInput__container input:focus {
  border: solid 1px #62b8ae;
}

.textInput__container .isInvalid {
  border: solid 1px #ff6161 !important;
  animation-name: bounceTextInput;
  animation-duration: 0.5s;
  animation-delay: 0.25s;
}

.textInput__container > input:read-only {
  background: #f1f1f1;
  border: solid 1px transparent;
  color: #999999;
}

.textInput--small-error {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #ff6161;
  display: block;
  margin-top: 10px !important;
}

@keyframes bounceTextInput {
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(5px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(4px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(2px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
}
