.dropdownSearch {
  width: 100%;
}

.dropdownSearch > header {
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #ffffff;
  height: 50px;
  width: 100%;
}

.dropdownSearch > .isOpen {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.dropdownSearch > header > input {
  border-radius: 5px;
  height: 30px;
  width: 100%;
  font-size: 1.25rem;
  outline: none;
  border: none;
  padding: 0 20px;
  background: #ffffff;
  text-transform: uppercase;
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #4d4d4d;
  margin: 0;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdownSearch > header > input::placeholder {
  font-size: 0.75rem !important;
}

.dropdownSearch > header > button {
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 20px;
  background: transparent;
}

.dropdownSearch > header > button > svg {
  fill: #62b8ae;
}

.dropdownSearch > main {
  width: 100%;
}

.dropdownSeach__listResult {
  background: #ffffff;
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
}

.dropdownSeach__listResult--list {
  padding: 0;
  margin: 0;
  max-height: 250px;
  overflow-y: auto;
  margin: 0px 10px 10px 20px;
}

.dropdownSeach__listResult--list li {
  list-style: none;
  width: 100%;
}

.dropdownSeach__listResult--list li button {
  width: 100%;
  background: none;
}

.dropdownSeach__listResult--list li button span {
  display: block;
  width: 100%;
  text-align: left;
  padding: 5px 0;
  text-transform: uppercase;
  color: #999999;
}

.dropdownSeach__listResult--list li button .isSelected {
  color: #4d4d4d;
  font-weight: bold;
}

.dropdownSearch__iconclose {
  transform: scale(1.5);
}

.dropdownSearch__iconclose > path {
  fill: #ff6161 !important;
}
