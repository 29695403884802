.animalcategory__container {
  padding: 30px 0 0 40px;
}

.animacategory__content {
  width: 500px;
}

.containerTable__title {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 43px;
  color: #4d4d4d;
  margin: 0;
  padding: 0;
}

.evolution__container:first-child {
  margin-top: 20px;
}

.evolution__container:not(:first-child) {
  margin-top: 20px;
}

/* aqui */
.evolution__container {
  max-width: 500px;
}

.evolution__container:not(:last-child) {
  border-bottom: solid 1px #f1f1f1;
}

.evolution__title {
  margin-bottom: 50px;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  color: #4d4d4d;
}

.containerTable__title {
  width: 90%;
  margin: 0 60px;
  margin-bottom: 20px;
}

.containerTable__modal--container {
  width: 90%;
  margin: 0 60px;
  height: 68%;
  margin-bottom: 30px;
}

@media only screen and (min-width: 1400px) {
  .containerTable__modal--container {
    height: auto;
    max-height: 100%;
  }

  .footermodal__edit {
    margin-top: 70px;
  }

  .containerTable__title {
    margin-bottom: 50px;
  }
}

.containerTable__modal {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  margin-right: 50px;
}

.containerTable__modal > form {
  border-radius: 10px 10px 0px 0px;
  overflow: overlay;
  height: 100%;
  border: solid 1px #f1f1f1;
}

.containerTable__modal > form::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.evolution__container > .containerTable_container--title > h2,
.evolution__container > .containerTable_container--title > button {
  margin-bottom: 0;
}

.evolution__container > .containerTable_container--title {
  margin-bottom: 20px;
}

.footermodal__edit > .footerContentModal__container > .buttononlytitle {
  width: 194px;
}

.footermodal__edit > .footerContentModal__container > .buttononlytitle:first-child {
  margin-right: 20px;
}

.divteste {
  display: flex;
  flex-direction: column;
}

.modalEdit__message--error {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 22px;
  color: #ff6161;
  display: block;
  margin-top: 8px;
}

.alert-modal--section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 20px;
  position: relative;
  width: 335px;
  height: 237px;
}

.alert-modal--section > h2 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.625rem;
  color: #4d4d4d;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.alert-modal--section > p {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: #999999;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
}

.alert-modal--button-close {
  background: none;
  border: none;
  outline: none;
}

.alert-modal--button-close > svg {
  font-size: 1.25rem;
}

.alert-modal--section-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.alert-modal--section-buttons > button {
  width: calc(50% - 5px);
  height: 52px;
  background: #4d4d4d;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-modal--section-buttons > button > strong {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  color: #ffffff;
  text-transform: uppercase;
  margin-left: 13px;
}

.containerTable__modal--container {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-areas:
    "header"
    "table"
    "footer";
  grid-template-rows: auto auto auto;
}

.containerTable__modal--container > .containertable__modal--header {
  grid-area: header;
}

.containerTable__modal--container > .containerTable__modal--container {
  grid-area: table;
  overflow: auto;
  padding: 0 50px;
}

.containerTable__modal--container > .footermodal__edit {
  grid-area: footer;
}
