.defaultmap__container {
  width: 100%;
  height: 100%;
  position: relative;
}

.defaultmap__container .leaflet-control-zoom,
.defaultmap__container .leaflet-control-attribution {
  display: none;
}

.defaultmap__container img[alt~="Google"] {
  display: none;
}

.defaultmap__container .rodal {
  z-index: 999;
}

.defaultmap__legend--container {
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 980;
  display: flex;
  align-items: center;
  justify-content: center;
}

.defaultmap__legend--container > span {
  display: block;
  width: 20px;
  height: 20px;
  background: #61fff6;
  border: 2px solid #ffffff;
  border-radius: 10px;
}

.defaultmap__legend--container > h4 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.03em;
  color: #ffffff;
  margin: 0;
  margin-left: 5px;
}

.filterpolygons__alerterror .rodal {
  position: absolute;
  z-index: 999;
}

.filterpolygons__alerterror--texts {
  margin-bottom: 10px;
}

.filterpolygons__alerterror--texts > h3 {
  margin-bottom: 10px !important;
}

.filterpolygons__alerterror .buttononlytitle {
  width: 152px;
}

.defaultmap__alert--content {
  width: 100%;
  height: 100%;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.defaultmap__alert--content .buttononlytitle {
  width: 152px;
}

.defaultmap__alert--texts {
  margin-bottom: 30px;
  margin-top: 20px;
}

.defaultmap__alert--texts h3 {
  margin-bottom: 10px !important;
}

.batches__container {
  width: 52px;
  height: 52px;
  border-radius: 32px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.defaultMap__floating--filters {
  position: absolute;
  z-index: 999;
  top: 30px;
  right: 30px;
  width: 260px;
  max-height: calc(100% - 60px);
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 10px;
}

.containerlegend__defaultmaps {
  display: flex;
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 999;
}

.containerlegend__defaultmaps > .legendRounded__container {
  margin-right: 10px;
}
