.SideBarShow {
  height: 100vh;
  width: 340px;
  background: #324440;
}

.SideBarHide {
  height: 100vh;
  width: 0px;
  background: #324440;
}

.sideBar_header {
  margin-top: 30px;
}

.sideBar_header>img {
  width: 350px;
  height: 64px;
  margin: auto;

}

.button__mapa {
  width: 100%;
  height: 52px;
  border-radius: 32px;
  padding: 6px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.6rem;
  text-decoration: none;
}

.button__mapa>svg {
  margin-right: 5px;
  width: 30px;
}

.button__mapa>svg>path {
  fill: #324440;
}

.button__mapa>strong {
  font-family: "Fira Sans", monospace;
  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #324440;
  margin-top: 0.3rem;
}

.button__dados {
  width: 100%;
  height: 52px;
  background: #FFFFFF;
  border-radius: 32px;
  padding: 6px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.button__dados>svg {
  margin-right: 5px;
  width: 30px;
}

.button__dados>svg>path {
  fill: #324440;
}

.button__dados>strong {
  font-family: "Fira Sans", monospace;
  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #324440;
  margin-top: 0.3rem;
}

.sideBar_header_buttons {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
  margin-top: 40px;
}

.button__createTask {
  width: 100%;
  height: 52px;
  border-radius: 32px;
  padding: 6px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button__createTask>svg {
  margin-right: 10px;
  width: 30px;
}

.button__createTask>svg>path {
  fill: #FFFFFF;
}

.sideBar_task_button{
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.divider {
  width: 100%;
  height: 1px;
  background: #999999;
}

.sideBar_footer {
  display: flex;
  padding: 20px 20px 0px 70px;
}

.sideBar_footer span {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 18px;
  letter-spacing: 0.02em;
  font-variant: small-caps;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
}

.sideBar_footer span:hover {
  color: #999999;
}

span.disabled {
  pointer-events: none;
  cursor: default;
}

.sideBar_footer_version {
  display: flex;
  padding: 20px 20px 0px 70px;
}

.sideBar_footer_version span {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 18px;
  letter-spacing: 0.02em;
  font-variant: small-caps;
  text-transform: uppercase;
  color: #FFFFFF;
}

.createTask_buttonWrapper {
  padding:20px;
}