.sidebar__card {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-left: 8px;
  border-left-style: solid;
  border-left-width: 10px;
  border-left-color: transparent;
  border-top: solid 1px #c4c4c4;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
}

.sidebar__card--left {
  width: 40px;
  height: 100%;
}

.sidebar__card--left>svg {
  width: 32px;
}

.sidebar__card--left>svg>path {
  fill: #000;
}

.sidebar__card--right {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 0.5rem;
  margin-top: 0.4rem;
  margin-bottom: 0.2rem;
}

.sidebar__card--right>strong {
  font-family: 'Fira Sans';
  font-style: normal;
  font-size: 1.05rem;
  line-height: 22px;
  text-transform: uppercase;
  color: #4D4D4D;
}

.sidebar__card--options {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

@media (min-width: 1440px) {
  .sidebar__card:last-child {
    border-bottom: solid 1px #c4c4c4;
  }
}