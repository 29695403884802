.login__container {
  width: 500px;
  margin-left: 100px;
  margin-top: 50px;
}

.login__container form {
  margin-top: 50px;
}

.login__container .buttontitle {
  width: 147px;
  height: 52px;
}

.login__container--title {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 4rem;
  color: #ffffff;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.login__container--subtitle {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.login__container--subtitle a {
  color: #57ce71;
  text-decoration-line: underline !important;
  text-underline-offset: 4px;
}

.login__container--subtitle a:hover {
  color: #57ce71;
}

.login__form--input {
  margin-bottom: 20px;
}

.login__form--forgot {
  background: none;
  border: none;
  outline: none;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  color: #57ce71;
  margin-bottom: 50px;
}

.login__container--error {
  display: block;
  position: relative;
  color: #FFFFFF;
  font-family: "Fira Sans", monospace;
  font-size: 0.9rem;
  margin-bottom:  20px;
}

.alert-modal--section-forget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 350px;
  height: 237px;
}

.alert-modal--section-forget {
  width: 100%;
  justify-content: flex-start;
}

.alert-modal--section-forget > h2 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.625rem;
  color: #4d4d4d;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  margin-top: 12px;
}

.alert-modal--section-forget > p {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: #999999;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
}

.alert-modal--section-buttons-voltar > button {
  width: 152px;
  height: 52px;
  background: #62B8AE;
  border-radius: 32px;
}

.alert-modal--section-buttons-voltar > button > strong {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  color: #ffffff;
  text-transform: uppercase;
}