.dashBoardInfo__container>div:not(:first-child) {
    margin-top: 10px;
}

.dashBoardInfoSplited__owner--container {
    background: #f1f1f1;
    border-radius: 10px;
    padding: 10px 20px;
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    border: 1px solid #000;
}

.dashBoardInfoSplited__owner--container>small {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #999999;
    text-transform: uppercase;
    cursor: default;
}

.dashBoardInfoSplited__owner--container>h6 {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #4d4d4d;
    text-transform: uppercase;
    margin: 0;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
    text-align: end;
}

.dashBoardInfo__dark--container {
    background: #4D4D4D;
    border-radius: 10px;
    padding: 10px 20px;
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    border: 1px solid #000;
}

.dashBoardInfo__dark--container>small {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #fff;
    text-transform: uppercase;
    cursor: default;
}

.dashBoardInfo__dark--container>h6 {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 54px;
    letter-spacing: 0.02em;
    color: #fff;
    text-transform: uppercase;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
    text-align: end;
}

.dashBoardInfoGreen__owner--container,
.dashBoardInfo__owner--container {
    background: #f1f1f1;
    border-radius: 10px;
    padding: 0 20px;
    width: 100%;
    height: 62px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #000;
}

.dashBoardInfoGreen__owner--container>small,
.dashBoardInfo__owner--container>small {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #999999;
    text-transform: uppercase;
    cursor: default;
}

.dashBoardInfo__owner--container>h6 {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.02em;
    color: #4d4d4d;
    text-transform: uppercase;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
    text-align: end;
}



.dashBoardInfoGreen__owner--container>h6 {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.02em;
    color: #57CE71;
    text-transform: uppercase;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
    text-align: end;
}

.dataFilter__owner--container {
    background: #fff;
    border-radius: 10px;
    padding: 0 20px;
    width: 230px;
    height: 50px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.dataFilter__owner--container>small {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #999999;
    text-transform: uppercase;
    cursor: default;
}

.dataFilter__owner--container>div {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
}

.dataFilter__owner--container>div>svg>g>path {
    fill: #62B8AE;
    color: #62B8AE;
}

.dataFilter__owner--container>div>h6 {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #4d4d4d;
    text-transform: uppercase;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
    text-align: end;
}

.tableDashboard {

    display: flex;
    flex-direction: column;
    gap: 5px;

    table {
        overflow: auto;
        width: 100%;
        display: block;
        margin: 0 auto;
        border-spacing: 0;
    }

    tbody {
        white-space: nowrap;
    }

    th,
    td {
        padding: 5px 10px;
        text-align: center;
        font-size: 14px;
    }

    th {
        position: sticky;
        top: 0;
        vertical-align: bottom;
    }

    table>thead>th,
    table>thead>tr>th {
        font-family: "Fira Sans";
        font-style: normal;
        font-weight: 600;
        text-align: center;
        vertical-align: middle;
    }

    table>tbody>tr>* {
        border-right: 1px solid #324440;
    }

    table>*>tr>*,
    table>*>tr>th {
        width: 70px;
        font-family: "Fira Sans";
        font-style: normal;
        font-weight: 400;
    }

    table>tbody>*>* {
        text-align: right;
    }

    table>tbody>*>*:first-child {
        text-align: left;
    }

    table>tbody>*>*:last-child {
        border-right: none;
    }


    table>tfoot>* {
        font-family: "Fira Sans";
        font-style: normal;
        font-weight: 600;
        text-align: right;
    }

    .table>tfoot>*:last-child {
        color: #57CE71;
    }

    h2 {
        font-family: "Fira Sans";
        font-size: 18px;
        font-weight: 600;
        line-height: 21.6px;
        letter-spacing: 0.03em;
        text-align: left;
        text-transform: uppercase;
        margin: 0;
    }
}

#saldoEstoque.tableDashboard table>tbody>tr>*:last-child {
    background-color: #57CE715d;
}

#categorias.tableDashboard table>tbody>tr>*:nth-child(2) {
    background-color: #57CE715d;
}

.dashBoardInfo__exportButton {
    font-family: "Fira Sans";
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: right;
    color: #62B8AE;
    background: transparent;
    margin: 20px 0;
}

.dashBoardInfoDatas__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.dashBoardSupplement__container {
    display: flex;
    gap: 30px;
    align-items: center;
}

.dashBoardInfoSupplement__container {
    display: flex;
    gap: 10px;
}

.dashBoardInfoSupplement__container>div {
    width: 250px;
}