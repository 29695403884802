.pagination__container {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.pagination__container > .ball__button {
  margin-right: 15px;
}

.pagination__container :last-child {
  margin-right: 0px;
}

.ball__button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  /*gap: 10px;*/
  width: 50px;
  height: 50px;
  border-radius: 25px;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}

.ball__button.ball__current {
  background: #62b8ae;
  color: #ffffff;
}

.ball__button.ball__normal {
  background: #ffffff;
  color: #4d4d4d;
  border: 1px solid #f1f1f1;
}

.ball__button.ball__normal:hover {
  background: #62b8ae;
  color: #ffffff;
}

.ball__button.ball__grey {
  background: #f1f1f1;
  color: #999999;
  fill: #999999;
  flex-flow: row;
  justify-content: space-around;
}
