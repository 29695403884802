.failSync-rowContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.failSync-clipBoard {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    border-radius: 10px;
}

.failSync-jsonContent {
    font-family: monospace;
    font-size: 12px;
    height: 200px;
    overflow-x: hidden;
}