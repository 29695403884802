.toolbox__container {
  position: absolute;
  bottom: 40px;
  left: 40px;
  z-index: 2;
}

.toolbox__container > h3 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  /* line-height: 26px; */
  text-align: left;
  /* letter-spacing: 0.03em; */
  color: #ffffff;
  text-transform: uppercase;
}

.toolbox__container > div {
  display: flex;
}
