.headerClose__button--error {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* margin-bottom: 20px; */
}

.headerClose__button--error > button {
  background: none;
  border: none;
  outline: none;
}
.headerClose__button--error > button > svg {
  width: 18px;
  height: 18px;
}
