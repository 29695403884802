.footerContentModal__container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 5%;
}

.footerContentModal__container > .buttontitle:first-child {
  margin-right: 8px;
}
