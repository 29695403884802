.microarea__divider {
  width: 100%;
  height: 2px;
  background: #f1f1f1;
  margin: 20px 0;
}

.microarea__title--container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.microarea__title--container h2 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5625rem;
  letter-spacing: 0.02em;
  line-height: 30px;
  color: #4d4d4d;
}

.microarea__title--container h3 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5625rem;
  letter-spacing: 0.02em;
  color: #4d4d4d;
}

.microarea__title--container button {
  background: transparent;
  border: none;
  outline: none;
}

.container__input label {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 22px;
  color: #4d4d4d;
  margin: 10px 0;
}
.microarea__header_trash{
  position: absolute;
  left: 27rem;
}

.microarea__drawarea--container {
  min-width: 400px;
  height: 300px;
  background: url("../../../../../assets/png/genericMap.png");
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  border-radius: 10px;
  margin-top: -35px;
}

.microarea__container {
  display: flex;
  justify-content: space-between;
}

.microbuttondraw__container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.microbuttondraw__container > svg {
  width: 18px;
  resize: both;
  margin-right: 16px;
}

.microbuttondraw__container > svg > path {
  fill: #ffffff;
}

.microbuttondraw__container > h3 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0 !important;
}

.hasMicroPolygon > div {
  padding: 0;
}

.hasMicroPolygon .gmnoprint {
  display: none;
}

.hasMicroPolygon .buttonwithchildren__container {
  width: 52px;
  height: 52px;
  position: absolute;
  left: 896px;
  margin-bottom: 20px;
}

.hasMicroPolygon .buttonwithchildren__container svg {
  margin: 0;
}

.noHasMicroPolygon .buttonwithchildren__container {
  margin: 20px;
}

.noHasMicroPolygon .buttonwithchildren__container svg {
  margin-right: 20px;
}

.microarea__content {
  display: flex;
  height: 300px;
}

.microarea__form {
  min-width: 460px;
  margin-right: 70px;
}

.microarea__drawarea--error {
  min-width: 400px;
}
