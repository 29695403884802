.rdp-day_selected {
    background: #62b8ae;
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background: #62b8ae57;
}

.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
    background: #62b8aeaa;
}

.rpd-days-range_selected {
    background-color: #62b8aecc;
    color: #ffffff;
}